import React, { useState, useEffect } from 'react';
import { db } from '../../../firebaseConfig';
import { doc, getDoc, updateDoc, collection, addDoc, deleteDoc, getDocs } from 'firebase/firestore';
import DashboardNavbar from '../DashboardNavbar';


function TestimonialsEditPage() {
  const [content, setContent] = useState({
    testimonials_title: '',
    testimonials_description: ''
  });

  const [testimonials, setTestimonials] = useState([]);
  const [newTestimonial, setNewTestimonial] = useState({
    testimonial_client_description: '',
    testimonial_client_name: '',
    testimonial_client_position: ''
  });

  useEffect(() => {
    const fetchContent = async () => {
      const docRef = doc(db, 'homeContent', 'heroSection');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setContent(docSnap.data());
      } else {
        console.log('No such document!');
      }
    };

    const fetchTestimonials = async () => {
      const querySnapshot = await getDocs(collection(db, 'homeContent', 'heroSection', 'testimonials'));
      const testimonialsData = [];
      querySnapshot.forEach((doc) => {
        testimonialsData.push({ id: doc.id, ...doc.data() });
      });
      setTestimonials(testimonialsData);
    };

    fetchContent();
    fetchTestimonials();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContent({
      ...content,
      [name]: value,
    });
  };

  const handleTestimonialChange = (e) => {
    const { name, value } = e.target;
    setNewTestimonial({
      ...newTestimonial,
      [name]: value,
    });
  };

  const addTestimonial = async (e) => {
    e.preventDefault();
    const testimonialRef = collection(db, 'homeContent', 'heroSection', 'testimonials');
    await addDoc(testimonialRef, newTestimonial);
    setNewTestimonial({
      testimonial_client_description: '',
      testimonial_client_name: '',
      testimonial_client_position: ''
    });
    alert('Testimonial added successfully!');
  };

  const deleteTestimonial = async (id) => {
    await deleteDoc(doc(db, 'homeContent', 'heroSection', 'testimonials', id));
    setTestimonials(testimonials.filter((testimonial) => testimonial.id !== id));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const docRef = doc(db, 'homeContent', 'heroSection');

    const updatedFields = {};
    for (const key in content) {
      if (content[key] !== '' && content[key] !== null) {
        updatedFields[key] = content[key];
      }
    }

    await updateDoc(docRef, updatedFields);
    alert('Content updated successfully!');
  };

  return (
    <>
      <DashboardNavbar />
      <section className="relative z-20 overflow-hidden bg-white py-12 lg:pt-[80px] border-t border-gray-50">
        <div className="max-w-2xl px-4 sm:px-6">
          <h2 className="mb-4 text-xl font-bold text-dark">Edit Testimonials Section</h2>
          <form onSubmit={handleSubmit}>
            <div className="grid gap-4 sm:grid-cols-1 sm:gap-6">
              
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-dark">Testimonials Title</label>
                <input
                  type="text"
                  name="testimonials_title"
                  value={content.testimonials_title}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Testimonials title"
                />
              </div>
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-dark">Testimonials Description</label>
                <textarea
                  name="testimonials_description"
                  value={content.testimonials_description}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Testimonials description"
                  rows="5"
                ></textarea>
              </div>
              <div className="sm:col-span-1">
                <h3 className="block mb-2 text-lg font-medium text-dark">Manage Testimonials</h3>
                {testimonials.map((testimonial, index) => (
                  <div key={index} className="mb-4 p-4 border rounded-md">
                    <p className="mb-2">{testimonial.testimonial_client_description}</p>
                    <p className="font-bold">{testimonial.testimonial_client_name}</p>
                    <p className="text-sm text-gray-600">{testimonial.testimonial_client_position}</p>
                    <button
                      type="button"
                      onClick={() => deleteTestimonial(testimonial.id)}
                      className="mt-2 text-sm text-red-600 hover:underline"
                    >
                      Delete
                    </button>
                  </div>
                ))}
                <h4 className="mt-4 text-lg font-medium text-dark">Add New Testimonial</h4>
                <div className="sm:col-span-1">
                  <label className="block mb-2 text-sm font-medium text-dark">Description</label>
                  <textarea
                    name="testimonial_client_description"
                    value={newTestimonial.testimonial_client_description}
                    onChange={handleTestimonialChange}
                    className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    placeholder="Testimonial description"
                    rows="3"
                  ></textarea>
                </div>
                <div className="sm:col-span-1">
                  <label className="block mb-2 text-sm font-medium text-dark">Client Name</label>
                  <input
                    type="text"
                    name="testimonial_client_name"
                    value={newTestimonial.testimonial_client_name}
                    onChange={handleTestimonialChange}
                    className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    placeholder="Client name"
                  />
                </div>
                <div className="sm:col-span-1">
                  <label className="block mb-2 text-sm font-medium text-dark">Client Position</label>
                  <input
                    type="text"
                    name="testimonial_client_position"
                    value={newTestimonial.testimonial_client_position}
                    onChange={handleTestimonialChange}
                    className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    placeholder="Client position"
                  />
                </div>
                <button
                  type="button"
                  onClick={addTestimonial}
                  className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-success-500 rounded-lg"
                >
                  Add Testimonial
                </button>
              </div>
            </div>

            <button type="submit" className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-info-500 rounded-lg">
              Update Testimonials
            </button>
          </form>
        </div>
      </section>
    </>
  );
}

export default TestimonialsEditPage;