// src/pages/Home.js
import React from 'react';
import HomeHero from '../components/HomeHero';
import HomeAbout from '../components/HomeAbout';
import HomeBranch from '../components/HomeBranch';
import HomeTestimonials from '../components/HomeTestimonials';
import HomeFaq from '../components/HomeFaq'; 
import HomeServices from '../components/HomeServices';


function Home() {
  return (
    <div>
      <HomeHero />
      <HomeAbout />
      <HomeServices />
      <HomeBranch />
      <HomeTestimonials />
      <HomeFaq />
    </div>
  );
}

export default Home;
