import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { ReactComponent as QuestionSvg } from '../assets/question.svg';

function HomeFaq() {
  const [faqs, setFaqs] = useState([]);
  const [content, setContent] = useState({
    faq_pre_title: '',
    faq_title: '',
    faq_description: ''
  });

  useEffect(() => {
    const fetchFaqContent = async () => {
      const docRef = doc(db, 'homeContent', 'heroSection');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setContent(docSnap.data());
      } else {
        console.log('No such document!');
      }
    };

    const fetchFaqs = async () => {
      const faqCollection = collection(db, 'homeContent', 'heroSection', 'faqs');
      const faqSnapshot = await getDocs(faqCollection);
      const faqList = faqSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setFaqs(faqList);
    };

    fetchFaqContent();
    fetchFaqs();
  }, []);

  return (
    <section className="relative z-10 overflow-hidden bg-white py-12 lg:pt-[80px] border-t border-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mb-[60px] text-start">
              <h2 className="mb-3 text-2xl sm:text-3xl font-bold text-dark">{content.faq_title}</h2>
              <p className="text-dark" dangerouslySetInnerHTML={{ __html: content.faq_description }}></p>
            </div>
          </div>
        </div>
        <div className="-mx-4 flex flex-wrap">
          {faqs.map(faq => (
            <div key={faq.id} className="w-full px-4 lg:w-1/2">
              <div className="mb-12 flex lg:mb-[70px]">
                <div className="mr-4 flex h-[50px] w-full max-w-[50px] items-center justify-center rounded-xl bg-primary-500 text-white sm:mr-6 sm:h-[60px] sm:max-w-[60px]">
                  <QuestionSvg />
                </div>
                <div className="w-full">
                  <h3 className="mb-3 text-lg font-semibold text-dark">{faq.faq_question_title}</h3>
                  <p className="text-dark text-dark">{faq.faq_question_description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default HomeFaq;