import React, { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function ContactForm() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    branch: 'slough',
    message: '',
  });

  const [submissionStatus, setSubmissionStatus] = useState(null);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Execute reCAPTCHA before submitting the form
    window.grecaptcha.ready(async () => {
      const token = await window.grecaptcha.execute('6Lfi4ysqAAAAAK90Q0sx1lPOZ37jlasBxM3NZJUH', {
        action: 'submit'
      });

      try {
        const response = await fetch('https://europe-west2-ukroofingsupplies-4b596.cloudfunctions.net/submitContactForm', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ ...formData, recaptchaToken: token }), // Send the token along with form data
        });

        if (!response.ok) {
          setSubmissionStatus('error');
          return;
        }

        const result = await response.json();

        if (result.success) {
          setSubmissionStatus('success');
          setFormData({
            fullName: '',
            email: '',
            phone: '',
            branch: 'slough',
            message: '',
          });
        } else {
          setSubmissionStatus('error');
        }
      } catch (error) {
        setSubmissionStatus('error');
      }
    });
  };

  return (
    <div className="py-10 px-2">
      <form onSubmit={handleSubmit}>
        <div className="mb-[22px] focus-within:text-primaryDark">
          <label htmlFor="fullName" className="block mb-4 text-sm text-body-color">Full Name*</label>
          <input
            type="text"
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            placeholder="Your full name"
            className="bg-transparent w-full text-body-color placeholder:text-body-color/60 border-0 border-b border-gray-300 pb-3 focus:border-primary focus:outline-none"
          />
        </div>
        <div className="mb-[22px]">
          <label htmlFor="email" className="block mb-4 text-sm text-body-color">Email*</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Your email"
            className="bg-transparent w-full text-body-color placeholder:text-body-color/60 border-0 border-b border-gray-300 pb-3 focus:border-primary focus:outline-none"
          />
        </div>
        <div className="mb-[22px]">
          <label htmlFor="phone" className="block mb-4 text-sm text-body-color">Phone (Optional)</label>
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Your phone number"
            className="bg-transparent w-full text-body-color placeholder:text-body-color/60 border-0 border-b border-gray-300 pb-3 focus:border-primary focus:outline-none"
          />
        </div>
        <div className="mb-[22px]">
          <label htmlFor="branch" className="block mb-4 text-sm text-body-color">Select a Branch (Optional)</label>
          <div className="relative">
            <select
              name="branch"
              value={formData.branch}
              onChange={handleChange}
              className="appearance-none bg-transparent w-full text-body-color placeholder:text-body-color/60 border-0 border-b border-gray-300 pb-3 focus:border-primary focus:outline-none"
            >
              <option value="slough">Slough Branch</option>
              <option value="reading">Reading Branch</option>
              <option value="oxford">Oxford Branch</option>
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
              <ArrowDropDownIcon />
            </div>
          </div>
        </div>
        <div className="mb-[30px]">
          <label htmlFor="message" className="block mb-4 text-sm text-body-color">Message*</label>
          <textarea
            name="message"
            rows="3"
            value={formData.message}
            onChange={handleChange}
            placeholder="Type your message here"
            className="bg-transparent w-full text-body-color placeholder:text-body-color/60 resize-none border-0 border-b border-gray-300 pb-3 focus:border-primary focus:outline-none"
          ></textarea>
        </div>
        <div className="mb-0">
          <button
            type="submit"
            className="inline-flex items-center justify-center px-10 py-3 text-base font-medium text-white transition duration-300 ease-in-out rounded-md bg-primary hover:bg-blue-dark"
          >
            Send
          </button>
        </div>

        {/* Alert messages */}
        {submissionStatus === 'success' && (
          <div className="mt-4 text-success-600">
            Your message was successfully sent! We will be in touch with you shortly.
          </div>
        )}
        {submissionStatus === 'error' && (
          <div className="mt-4 text-red">
            Something went wrong. Please try again, or give us a call.
          </div>
        )}
      </form>
    </div>
  );
}

export default ContactForm;
