import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics, setAnalyticsCollectionEnabled } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyBuK9lqYoFYNjmkxLzoETbJh28nPW7DDGc",
  authDomain: "ukroofingsupplies-4b596.firebaseapp.com",
  projectId: "ukroofingsupplies-4b596",
  storageBucket: "ukroofingsupplies-4b596.appspot.com",
  messagingSenderId: "282896752262",
  appId: "1:282896752262:web:109a774766d8b441633b07",
  measurementId: "G-Z8SJLG6EH4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Disable analytics collection by default until consent is given
setAnalyticsCollectionEnabled(analytics, false);

export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

// Function to enable analytics when the user consents
export const enableAnalytics = () => {
  setAnalyticsCollectionEnabled(analytics, true);
};
