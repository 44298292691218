import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import ukRoofingSuppliesLogo from '../assets/ukroofingsupplies-logo.jpeg';

// Import Material-UI Icons
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import StoreIcon from '@mui/icons-material/Store';
import InfoIcon from '@mui/icons-material/Info';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LogoutIcon from '@mui/icons-material/Logout';

import IconButton from '@mui/material/IconButton';

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const auth = getAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user); // Set the user state when logged in
    });

    return () => unsubscribe();
  }, [auth]);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      document.body.style.overflow = 'hidden'; // Prevent scrolling
    } else {
      document.body.style.overflow = ''; // Restore scrolling
    }
  };

  const handleCloseMenu = () => {
    setIsMenuOpen(false);
    document.body.style.overflow = ''; // Restore scrolling
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setCurrentUser(null); // Reset the user state on logout
      handleCloseMenu(); // Close the menu after logout
      navigate('/'); // Redirect to the home page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <nav className="bg-primaryDark border-primary-500">
      <div className="max-w-screen-7xl mx-auto flex flex-wrap items-center justify-between py-1 px-4 lg:px-8">
        <Link to="/" className="flex py-1 items-center space-x-3 rtl:space-x-reverse">
          <img 
            src={ukRoofingSuppliesLogo} 
            className="h-20" 
            alt="UK Roofing Supplies Logo" 
            width="160" 
            height="auto" 
          />
        </Link>
        <div className="flex items-center space-x-3 md:space-x-0 rtl:space-x-reverse md:order-3">
          <div className="hidden md:flex items-center justify-between w-full md:w-auto" id="navbar-user">
            <ul className="flex font-medium p-4 md:p-0 mt-4 md:mt-0 rounded-lg bg-primaryDark md:space-x-8 rtl:space-x-reverse flex-row border-0">
              <li>
                <Link to="/" className="block py-2 px-3 text-white bg-primaryDark rounded md:bg-transparent md:p-0" aria-current="page">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/products" className="block py-2 px-3 text-white rounded md:p-0">
                  Products
                </Link>
              </li>
              <li>
                <Link to="/about" className="block py-2 px-3 text-white rounded md:p-0">
                  About
                </Link>
              </li>
              <li>
                <Link to="/branches" className="block py-2 px-3 text-white rounded md:p-0">
                  Branches
                </Link>
              </li>
              <li>
                <Link to="/contact" className="block py-2 px-3 text-white rounded md:p-0">
                  Contact
                </Link>
              </li>
              
              {currentUser && (
                <>
                  <li>
                    <Link to="/dashboard/products" className="block py-2 px-3 text-white rounded md:p-0">
                      Dashboard
                    </Link>
                  </li>
                  <li>
                    <button
                      onClick={handleLogout}
                      className="block py-2 px-3 text-white rounded md:p-0"
                    >
                      Logout
                    </button>
                  </li>
                </>
              )}
            </ul>
          </div>
          <div className="md:hidden">
            <div
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-white rounded-lg md:hidden focus:outline-none"
              onClick={handleMenuToggle}
              aria-controls="drawer-disable-body-scrolling"
              aria-expanded={isMenuOpen}
              role="button"
              tabIndex="0"
            >
              {isMenuOpen ? (
                <CloseIcon className="text-white" />
              ) : (
                <MenuIcon className="text-white" />
              )}
            </div>

          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        style={{ width: '20rem' }}
        id="drawer-disable-body-scrolling"
        className={`fixed top-0 left-0 z-50 h-screen p-4 overflow-y-auto transition-transform transform ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} bg-primaryDark shadow-lg`}
        tabIndex="-1"
        aria-labelledby="drawer-disable-body-scrolling-label"
      >

        <Link to="/" className="flex ml-1 items-center space-x-3 rtl:space-x-reverse">
          <img 
            src={ukRoofingSuppliesLogo} 
            className="h-20" 
            alt="UK Roofing Supplies Logo" 
            width="160" 
            height="auto" 
          />
        </Link>
       
        <div className="py-4 overflow-y-auto bg-primaryDark">
          <ul className="space-y-2 font-medium">
            <li>
              <Link to="/" className="flex items-center p-2 text-primary-200 rounded-md hover:bg-primary-800 hover:text-white group" onClick={handleCloseMenu}>
                <HomeIcon className="text-primary-200 group-hover:text-white" />
                <span className="ml-3 text-primary-200 group-hover:text-white">Home</span>
              </Link>
            </li>
            <li>
              <Link to="/products" className="flex items-center p-2 text-primary-200 rounded-md hover:bg-primary-800 hover:text-white group" onClick={handleCloseMenu}>
                <StoreIcon className="text-primary-200 group-hover:text-white" />
                <span className="ml-3 text-primary-200 group-hover:text-white">Products</span>
              </Link>
            </li>
            <li>
              <Link to="/about" className="flex items-center p-2 text-primary-200 rounded-md hover:bg-primary-800 hover:text-white group" onClick={handleCloseMenu}>
                <InfoIcon className="text-primary-200 group-hover:text-white" />
                <span className="ml-3 text-primary-200 group-hover:text-white">About</span>
              </Link>
            </li>
            <li>
              <Link to="/branches" className="flex items-center p-2 text-primary-200 rounded-md hover:bg-primary-800 hover:text-white group" onClick={handleCloseMenu}>
                <StoreIcon className="text-primary-200 group-hover:text-white" />
                <span className="ml-3 text-primary-200 group-hover:text-white">Branches</span>
              </Link>
            </li>
            <li>
              <Link to="/contact" className="flex items-center p-2 text-primary-200 rounded-md hover:bg-primary-800 hover:text-white group" onClick={handleCloseMenu}>
                <MailIcon className="text-primary-200 group-hover:text-white" />
                <span className="ml-3 text-primary-200 group-hover:text-white">Contact</span>
              </Link>
            </li>
            {currentUser && (
              <>
                <li>
                  <Link to="/dashboard/products" className="flex items-center p-2 text-primary-200 rounded-md hover:bg-primary-800 hover:text-white group" onClick={handleCloseMenu}>
                    <DashboardIcon className="text-primary-200 group-hover:text-white" />
                    <span className="ml-3 text-primary-200 group-hover:text-white">Dashboard</span>
                  </Link>
                </li>
                <li>
                  <button
                    onClick={handleLogout}
                    className="flex items-center p-2 text-primary-200 rounded-md hover:bg-primary-800 hover:text-white group"
                  >
                    <LogoutIcon className="text-primary-200 group-hover:text-white" />
                    <span className="ml-3 text-primary-200 group-hover:text-white">Logout</span>
                  </button>
                </li>
              </>
            )}
          </ul>
          <div className="my-4 border-t border-primary-200"></div>
          <div className="text-primary-200">
            <div className="flex items-center mb-4">
              <span className="text-primary-200 font-semibold">Head Office</span>
            </div>
            <div className="flex items-center mb-2">
              <LocationOnIcon className="text-primary-200 mt-[-12px]" />
              <span className="ml-3">Unit 1 Petersfield Avenue, Slough, Berkshire, SL2 5EA</span>
            </div>
            <div className="flex items-center mb-2">
              <PhoneIcon className="text-primary-200" />
              <span className="ml-3">01753 539820</span>
            </div>
            <div className="flex items-center mb-2">
              <MailIcon className="text-primary-200" />
              <span className="ml-3">slough@ukroofingsupplies.co.uk</span>
            </div>
          </div>
        </div>
      </div>

      {/* Dark Overlay */}
      {isMenuOpen && (
        <div
          id="overlay"
          className="fixed inset-0 bg-black bg-opacity-70 z-40"
          onClick={handleCloseMenu}
        ></div>
      )}
    </nav>
  );
}

export default Navbar;
