import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig'; // Adjust the path based on your project structure
import { doc, getDoc } from 'firebase/firestore';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import ScheduleIcon from '@mui/icons-material/Schedule';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

const BranchTabs = () => {
  const [branches, setBranches] = useState([]);
  const [activeTab, setActiveTab] = useState('');
  const [branchInfo, setBranchInfo] = useState({});

  // Fetch branches data from heroSection in Firestore
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const docRef = doc(db, 'homeContent', 'heroSection');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          const fetchedBranches = data.branches.map((branch, index) => ({
            name: branch.branch_name,
            address: branch.address,
            hoursWeekdays: branch.hours_weekdays,
            hoursWeekends: branch.hours_weekends,
            email: branch.email,
            phone: branch.phone,
            iframeSrc: index === 0 
              ? 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8386.10523983277!2d-0.5960492053521086!3d51.51158834315508!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876654a7447ec23%3A0x3afb5f3cef4da5cc!2sUK%20Roofing%20%26%20Plastic%20Supplies%20Ltd!5e0!3m2!1sen!2suk!4v1504766487462' 
              : index === 1 
              ? 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d942.9467452583136!2d-1.001472438165727!3d51.461768975010564!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48769bf9b1f18697%3A0xd697762df1db1943!2sUK%20Roofing%20%26%20Plastic%20Supplies%20Ltd!5e0!3m2!1sen!2sgr!4v1723040098662'
              : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2470.6667778305323!2d-1.1944588999999999!3d51.739129899999995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876c1a2ca335c3b%3A0xe13dd78bad041402!2sUK%20Roofing%20%26%20Plastic%20Supplies%20Ltd!5e0!3m2!1sen!2sgr!4v1723040215565'
          }));

          setBranches(fetchedBranches);
          setActiveTab(fetchedBranches[0].name); // Set the first branch as the active tab by default
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching branches:', error);
      }
    };

    fetchBranches();
  }, []);

  useEffect(() => {
    const selectedBranch = branches.find(branch => branch.name === activeTab);
    if (selectedBranch) {
      setBranchInfo(selectedBranch);
    }
  }, [activeTab, branches]);

  return (
    <div className="mx-auto">
      <div className="flex space-x-4 overflow-x-auto">
        {branches.map((branch, index) => (
          <button
            key={index}
            onClick={() => setActiveTab(branch.name)}
            className={`px-4 py-1 text-md font-semibold text-dark whitespace-nowrap ${
              activeTab === branch.name
                ? 'border-b-2 border-primaryDark mb-2'
                : ''
            } focus:outline-none`}
          >
            {branch.name}
          </button>
        ))}
      </div>
      <div className="bg-white rounded shadow mt-4">
        <div className="mb-4 px-4 pt-2">
          <h4 className="text-md font-semibold text-dark mb-2">{branchInfo.name} Branch</h4>
          <p className="text-sm text-gray-700 font-semibold mb-1">
            <LocationOnIcon className="mr-2 text-primary-500" />
            {branchInfo.address}
          </p>
          <p className="text-sm text-gray-700 mb-1">
            <ScheduleIcon className="mr-2 text-primary-500" />
            {branchInfo.hoursWeekdays}
          </p>
          <p className="text-sm text-gray-700 mb-1">
            <ScheduleIcon className="mr-2 text-primary-500" />
            {branchInfo.hoursWeekends}
          </p>
          <p className="text-sm text-gray-700 mb-1">
            <EmailIcon className="mr-2 text-primary-500" />
            {branchInfo.email}
          </p>
          <p className="text-sm text-gray-700 mb-1">
            <PhoneIcon className="mr-2 text-primary-500" />
            {branchInfo.phone}
          </p>
        </div>
        <div id="cd-google-map">
          <iframe 
            src={branchInfo.iframeSrc}
            width="100%" 
            height="400px" 
            frameBorder="0" 
            style={{border:0}} 
            allowFullScreen="" 
            aria-hidden="false" 
            tabIndex="0"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default BranchTabs;
