import React, { useState, useEffect } from 'react';
import { db, storage } from '../../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import DashboardNavbar from './DashboardNavbar';

function EditContactPage() {
  const [contactImageUrl, setContactImageUrl] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [contactHeroTitle, setContactHeroTitle] = useState('');
  const [contactHeroDescription, setContactHeroDescription] = useState('');

  useEffect(() => {
    const fetchContent = async () => {
      const docRef = doc(db, 'homeContent', 'contactPage');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.contactImageUrl) {
          setContactImageUrl(data.contactImageUrl);
        }
        if (data.contact_page_hero_title) {
          setContactHeroTitle(data.contact_page_hero_title);
        }
        if (data.contact_page_hero_description) {
          setContactHeroDescription(data.contact_page_hero_description);
        }
      } else {
        console.log('No such document!');
      }
    };

    fetchContent();
  }, []);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  const handleDeleteImage = async () => {
    if (contactImageUrl) {
      const imageRef = ref(storage, contactImageUrl);
      await deleteObject(imageRef);
      setContactImageUrl('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let updatedImageUrl = contactImageUrl;

    if (imageFile) {
      const imageRef = ref(storage, `contact/${imageFile.name}`);
      await uploadBytes(imageRef, imageFile);
      updatedImageUrl = await getDownloadURL(imageRef);
    }

    const docRef = doc(db, 'homeContent', 'contactPage');
    await updateDoc(docRef, {
      contactImageUrl: updatedImageUrl,
      contact_page_hero_title: contactHeroTitle,
      contact_page_hero_description: contactHeroDescription,
    });

    setContactImageUrl(updatedImageUrl);
    alert('Contact page hero section updated successfully!');
  };

  return (
    <>
      <DashboardNavbar />
      <section className="relative z-20 overflow-hidden bg-white py-12 lg:pt-[80px] border-t border-gray-50">
        <div className="max-w-2xl px-4 sm:px-6">
          <h2 className="text-xl font-bold text-dark">Edit Contact Page Hero Section</h2>
          <small className="text-success-700">
            This section allows you to update the hero image, title, and description for the contact page.
          </small>
          
          <form onSubmit={handleSubmit} className="mt-6 space-y-6">
            <div className="sm:col-span-1">
              <label className="block mb-2 text-sm font-medium text-dark">Contact Page Hero Image</label>
              {contactImageUrl && (
                <div className="mb-4">
                  <img src={contactImageUrl} alt="Contact Hero" className="w-full h-64 object-cover rounded-t-lg" />
                  <button
                    type="button"
                    onClick={handleDeleteImage}
                    className="mt-2 text-sm text-red-600 hover:underline"
                  >
                    Delete Image
                  </button>
                </div>
              )}
              <input
                type="file"
                onChange={handleFileChange}
                className="block w-full text-sm text-dark border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600"
              />
            </div>

            <div className="sm:col-span-1">
              <label htmlFor="contactHeroTitle" className="block mb-2 text-sm font-medium text-dark">
                Contact Page Hero Title
              </label>
              <input
                type="text"
                id="contactHeroTitle"
                value={contactHeroTitle}
                onChange={(e) => setContactHeroTitle(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 text-dark shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              />
            </div>

            <div className="sm:col-span-1">
              <label htmlFor="contactHeroDescription" className="block mb-2 text-sm font-medium text-dark">
                Contact Page Hero Description
              </label>
              <textarea
                id="contactHeroDescription"
                value={contactHeroDescription}
                onChange={(e) => setContactHeroDescription(e.target.value)}
                rows="4"
                className="block w-full rounded-md border-0 py-1.5 text-dark shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
              ></textarea>
            </div>

            <button
              type="submit"
              className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-info-500 rounded-lg"
            >
              Update Hero Section
            </button>
          </form>
        </div>
      </section>
    </>
  );
}

export default EditContactPage;
