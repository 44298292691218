import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';

const HomeServices = () => {
  const [servicesContent, setServicesContent] = useState(null);
  const [services, setServices] = useState([]);

  useEffect(() => {
    const fetchServicesContent = async () => {
      try {
        const contentDocRef = doc(db, 'homeContent', 'heroSection');
        const contentDocSnap = await getDoc(contentDocRef);

        if (contentDocSnap.exists()) {
          setServicesContent(contentDocSnap.data());
        } else {
          console.log('No such document!');
        }

        const servicesCollectionRef = collection(db, 'homeContent', 'heroSection', 'services');
        const servicesQuerySnapshot = await getDocs(servicesCollectionRef);
        const servicesData = [];
        servicesQuerySnapshot.forEach((doc) => {
          servicesData.push({ id: doc.id, ...doc.data() });
        });
        setServices(servicesData);
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchServicesContent();
  }, []);

  // Return null if servicesContent is not available
  if (!servicesContent) {
    return null;
  }

  const { home_services_pre_title, home_services_title, home_services_description } = servicesContent;

  return (
    <section id="services" className="bg-white py-12 lg:py-[80px] border-t border-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="text-start mb-12">
          <h2 className="mb-3 text-2xl sm:text-3xl font-bold text-dark">{home_services_title}</h2>
          <p className="text-start text-dark">{home_services_description}</p>
        </div>
        <div className="flex overflow-x-auto snap-x snap-mandatory">
          <div className="flex space-x-4 md:space-x-8 scrollbar-visible">
            {services.map((service, index) => (
              <div key={index} className="flex-shrink-0 w-96 snap-start mb-6">
                <div className="block shadow-6 border rounded-lg border-gray-100 h-full flex flex-col">
                  <img
                    alt=""
                    src={service.service_image}
                    className="h-64 w-full object-cover sm:h-80 lg:h-76"
                  />
                  <div className="flex-grow px-6 py-4 flex flex-col">
                    <h3 className="text-lg font-bold text-dark sm:text-xl">{service.service_title}</h3>
                    <p className="mt-2 text-dark flex-grow">
                      {service.service_description}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeServices;
