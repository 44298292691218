import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig'; 
import { doc, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import HomeImageWithSvg from './HomeImageWithSvg';
import nfrcLogo from '../assets/nfrc.webp';

function HomeHero() {
  const [heroContent, setHeroContent] = useState(null);

  useEffect(() => {
    const fetchHeroContent = async () => {
      try {
        const docRef = doc(db, 'homeContent', 'heroSection');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          console.log('Document data:', docSnap.data());
          setHeroContent(docSnap.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchHeroContent();
  }, []);

  // Return null if heroContent is not available
  if (!heroContent) {
    return null;
  }

  const { hero_title, hero_description, hero_cta1, hero_cta1_link, hero_cta2, hero_cta2_link, brands } = heroContent;

  console.log('Rendered with heroContent:', heroContent);

  return (
    <div id="home" className="relative overflow-hidden bg-primaryDark pt-20">
      <div className="max-w-screen-7xl mx-auto px-8 sm:px-6 relative h-full">
        <div className="-mx-4 flex flex-wrap items-center h-full z-50">
          <div className="w-full">
            <div className="hero-content mx-auto max-w-[780px] text-center">
              <h1 className="mb-6 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug lg:text-5xl lg:leading-[1.2]">
                {hero_title}
              </h1>
              <p className="mx-auto mb-9 max-w-[600px] text-dark font-medium text-white sm:text-lg sm:leading-[1.44]">
                {hero_description}
              </p>
              <ul className="mb-10 flex flex-wrap items-center justify-center gap-5">
                <li>
                  <Link 
                      to={hero_cta1_link} 
                      className="inline-flex items-center justify-center rounded-md bg-white px-7 py-[14px] text-center text-dark font-medium"
                    >
                    {hero_cta1}
                  </Link>
                </li>
                <li>
                  <Link 
                      to={hero_cta2_link} 
                      className="inline-flex items-center justify-center rounded-md bg-red px-7 py-[14px] text-center font-medium text-white"
                    >
                    {hero_cta2}
                  </Link>
                </li>
              </ul>
              <div>
                <p className="mb-4 text-center text-dark font-medium text-white"> Leading the industry since 1977 </p>
                <div className="flex items-center justify-start gap-4 text-center overflow-x-auto">
                  {brands && brands.map((brand, index) => (
                    <img key={index} src={brand} className='h-8' width='100' height='32' alt={`Brand ${index + 1}`} />
                  ))}
                </div>
                <div className="flex items-center justify-center gap-4 mt-8 text-center">
                  <img src={nfrcLogo} className="h-10" alt="NFRC" />
                </div>
              </div>
            </div>
          </div>
          <HomeImageWithSvg />
        </div>
      </div>
    </div>
  );
}

export default HomeHero;
