import React, { useState, useEffect } from 'react';
import { db, storage } from '../../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import DashboardNavbar from './DashboardNavbar';

function EditBranchPage() {
  const [branches, setBranches] = useState([
    {
      branch_name: 'Slough Branch | Head Office',
      phone: '',
      email: '',
      hours_weekdays: '',
      hours_weekends: '',
      address: '',
    },
    {
      branch_name: 'Reading Branch',
      phone: '',
      email: '',
      hours_weekdays: '',
      hours_weekends: '',
      address: '',
    },
    {
      branch_name: 'Oxford Branch',
      phone: '',
      email: '',
      hours_weekdays: '',
      hours_weekends: '',
      address: '',
    },
  ]);

  const [heroBranchImage, setHeroBranchImage] = useState(null);  // To store the file
  const [heroBranchImageUrl, setHeroBranchImageUrl] = useState('');  // To store the URL
  const [heroContent, setHeroContent] = useState({
    branch_hero_title: '',
    branch_hero_description: '',
  });

  useEffect(() => {
    const fetchContent = async () => {
      const docRef = doc(db, 'homeContent', 'heroSection');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.branches && Array.isArray(data.branches)) {
          setBranches(data.branches);
        }
        if (data.heroBranchImageUrl) {
          setHeroBranchImageUrl(data.heroBranchImageUrl);
        }
        setHeroContent({
          branch_hero_title: data.branch_hero_title,
          branch_hero_description: data.branch_hero_description,
        });
      } else {
        console.log('No such document!');
      }
    };

    fetchContent();
  }, []);

  const handleBranchChange = (index, e) => {
    const { name, value } = e.target;
    const updatedBranches = [...branches];
    updatedBranches[index] = {
      ...updatedBranches[index],
      [name]: value,
    };
    setBranches(updatedBranches);
  };

  const handleHeroContentChange = (e) => {
    const { name, value } = e.target;
    setHeroContent({
      ...heroContent,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setHeroBranchImage(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let updatedHeroBranchImageUrl = heroBranchImageUrl;

    if (heroBranchImage) {
      const imageRef = ref(storage, `heroBranchImages/${heroBranchImage.name}`);
      await uploadBytes(imageRef, heroBranchImage);
      updatedHeroBranchImageUrl = await getDownloadURL(imageRef);
    }

    const docRef = doc(db, 'homeContent', 'heroSection');
    try {
      await updateDoc(docRef, {
        branches,
        heroBranchImageUrl: updatedHeroBranchImageUrl,
        branch_hero_title: heroContent.branch_hero_title,
        branch_hero_description: heroContent.branch_hero_description,
      });
      alert('Branch information and Hero image updated successfully!');
    } catch (error) {
      console.error('Error updating document: ', error);
      alert('Failed to update branch information or Hero image.');
    }
  };

  return (
    <>
      <DashboardNavbar />
      <section className="relative z-20 overflow-hidden bg-white py-12 lg:pt-[80px] border-t border-gray-50">
        <div className="max-w-2xl px-4 sm:px-6">
          <h2 className="text-xl font-bold text-dark">Edit Branch Page</h2>
          <small className="text-success-700">
            This section here is for the branches page section, but be aware the footer also uses this data. Please be careful when editing to ensure the character length of the text does not overflow the elements in Mobile and in Desktop screen size.
          </small>
          
          <form onSubmit={handleSubmit} className='mt-6'>
            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-dark">Hero Section Title</label>
              <input
                type="text"
                name="branch_hero_title"
                value={heroContent.branch_hero_title}
                onChange={handleHeroContentChange}
                className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Hero section title"
              />
            </div>

            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-dark">Hero Section Description</label>
              <textarea
                name="branch_hero_description"
                value={heroContent.branch_hero_description}
                onChange={handleHeroContentChange}
                className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Hero section description"
                rows="4"
              ></textarea>
            </div>

            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-dark">Hero Section Background Image</label>
              {heroBranchImageUrl && (
                <div className="mb-4">
                  <img src={heroBranchImageUrl} alt="Hero Background" className="w-full h-64 object-cover rounded-t-lg" />
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className="block w-full text-sm text-dark border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600"
              />
            </div>

            {branches.map((branch, index) => (
              <div key={index} className="mb-6 border-b pb-6">
                <h3 className="text-lg font-semibold text-dark mb-4">{branch.branch_name}</h3>
                <div className="grid gap-4 sm:grid-cols-1 sm:gap-6">
                  <div className="sm:col-span-1">
                    <label className="block mb-2 text-sm font-medium text-dark">Phone</label>
                    <input
                      type="text"
                      name="phone"
                      value={branch.phone}
                      onChange={(e) => handleBranchChange(index, e)}
                      className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="Phone number"
                    />
                  </div>
                  <div className="sm:col-span-1">
                    <label className="block mb-2 text-sm font-medium text-dark">Email</label>
                    <input
                      type="email"
                      name="email"
                      value={branch.email}
                      onChange={(e) => handleBranchChange(index, e)}
                      className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="Email address"
                    />
                  </div>
                  <div className="sm:col-span-1">
                    <label className="block mb-2 text-sm font-medium text-dark">Working Hours (Weekdays)</label>
                    <textarea
                      name="hours_weekdays"
                      value={branch.hours_weekdays}
                      onChange={(e) => handleBranchChange(index, e)}
                      className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="Working hours (Weekdays)"
                      rows="3"
                    ></textarea>
                  </div>
                  <div className="sm:col-span-1">
                    <label className="block mb-2 text-sm font-medium text-dark">Working Hours (Weekends)</label>
                    <textarea
                      name="hours_weekends"
                      value={branch.hours_weekends}
                      onChange={(e) => handleBranchChange(index, e)}
                      className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="Working hours (Weekends)"
                      rows="3"
                    ></textarea>
                  </div>
                  <div className="sm:col-span-1">
                    <label className="block mb-2 text-sm font-medium text-dark">Address</label>
                    <textarea
                      name="address"
                      value={branch.address}
                      onChange={(e) => handleBranchChange(index, e)}
                      className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                      placeholder="Branch address"
                      rows="4"
                    ></textarea>
                  </div>
                </div>
              </div>
            ))}

            <button
              type="submit"
              className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-info-500 rounded-lg"
            >
              Update Branch Information and Hero Image
            </button>
          </form>
        </div>
      </section>
    </>
  );
}

export default EditBranchPage;
