import React, { useState, useEffect } from 'react';
import { db, storage } from '../../../firebaseConfig';
import { doc, getDoc, updateDoc, collection, getDocs, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import DashboardNavbar from '../DashboardNavbar';


function EditProductPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [tag, setTag] = useState('');
  const [image, setImage] = useState(null);
  const [existingImageUrl, setExistingImageUrl] = useState('');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const docRef = doc(db, 'products', id);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const productData = docSnap.data();
          setTitle(productData.title);
          setDescription(productData.description);
          setCategoryName(productData.categoryName);
          setTag(productData.tag);
          setExistingImageUrl(productData.imageUrl);
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    };

    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'categories'));
        const categoriesData = [];
        querySnapshot.forEach((doc) => {
          categoriesData.push(doc.data().name);
        });
        setCategories(categoriesData);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchProduct();
    fetchCategories();
  }, [id]);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (title.length < 3 || title.length > 50) {
      alert('Title must be between 3 and 50 characters');
      return;
    }

    if (description.length < 50 || description.length > 600) {
      alert('Description must be between 50 and 600 characters');
      return;
    }

    let imageUrl = existingImageUrl;
    if (image) {
      const imageRef = ref(storage, `products/${image.name}`);
      await uploadBytes(imageRef, image);
      imageUrl = await getDownloadURL(imageRef);
    }

    const docRef = doc(db, 'products', id);
    try {
      await updateDoc(docRef, {
        title,
        description,
        categoryName,
        tag,
        imageUrl: imageUrl || '', // Ensure imageUrl is not undefined
      });
      alert('Product updated successfully!');
      navigate('/dashboard/products', { state: { selectedCategory: location.state?.selectedCategory } });
    } catch (error) {
      console.error('Error updating product:', error);
      alert('Error updating product');
    }
  };

  const handleDelete = async () => {
    const confirmDelete = window.confirm('Are you sure you want to delete this product?');
    if (!confirmDelete) return;

    const docRef = doc(db, 'products', id);

    try {
      // Delete the image from Firebase Storage if it exists
      if (existingImageUrl) {
        const imageRef = ref(storage, existingImageUrl);
        await deleteObject(imageRef);
      }

      // Delete the product document from Firestore
      await deleteDoc(docRef);
      alert('Product deleted successfully!');
      navigate('/dashboard/products', { state: { selectedCategory: location.state?.selectedCategory } });
    } catch (error) {
      console.error('Error deleting product:', error);
      alert('Error deleting product');
    }
  };

  return (
    <>
      <DashboardNavbar />
      <section className="relative z-20 overflow-hidden bg-white py-12 lg:pt-[80px] border-t border-gray-50">
        <div className="max-w-2xl px-4 sm:px-6">
          <h2 className="mb-4 text-xl font-bold text-dark">Edit Product</h2>
          <form onSubmit={handleSubmit}>
            <div className="grid gap-4 sm:grid-cols-1 sm:gap-6">
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-dark">Title</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Product Title"
                  required
                />
              </div>
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-dark">Description</label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Product Description"
                  required
                ></textarea>
              </div>
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-dark">Category</label>
                <select
                  value={categoryName}
                  onChange={(e) => setCategoryName(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  required
                >
                  <option value="">Select Category</option>
                  {categories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-dark">Tag</label>
                <input
                  type="text"
                  value={tag}
                  onChange={(e) => setTag(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Product Tag"
                />
              </div>
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-dark">Image</label>
                <input
                  type="file"
                  onChange={handleImageChange}
                  className="block w-full text-sm text-dark border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600"
                />
                {existingImageUrl && !image && (
                  <img src={existingImageUrl} className="h-auto max-w-full rounded-lg object-cover mt-4" alt="Product" />
                )}
              </div>
            </div>
            <div className="flex items-center justify-between">
              <button type="submit" className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-info-500 rounded-lg">
                Update Product
              </button>
              <button type="button" onClick={handleDelete} className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-red rounded-lg">
                Delete Product
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}

export default EditProductPage;