import React, { useState, useEffect } from 'react';
import Hero from '../components/Hero';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';

function About() {
  const [content, setContent] = useState({});

  useEffect(() => {
    const fetchAboutContent = async () => {
      const docRef = doc(db, 'homeContent', 'aboutSection');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setContent(docSnap.data());
      } else {
        console.log('No such document!');
      }
    };

    fetchAboutContent();
  }, []);

  const {
    aboutHeroTitle,
    aboutHeroDescription,
    aboutTitle,
    aboutDescription,
    aboutMissionTitle,
    aboutMissionDescription,
    showMission,
    aboutValuesTitle,
    aboutValuesDescription,
    showValues,
    firstSideImageUrl,
    secondSideImageUrl,
    bottomImageUrl,
  } = content;

  return (
    <>
      <Hero 
        className=""
        title={aboutHeroTitle || "About Us!"}
        description={aboutHeroDescription || "Learn more about us!"}
        backgroundImage={content.heroImageUrl} 
      />
      <section className="bg-white py-12 lg:py-[80px] border-t border-gray-50">
        <div className="max-w-screen-2xl mx-auto px-4 sm:px-4">
          <div className="flex flex-wrap">
            <div className="w-full px-4 lg:w-8/12">
              <div>
                
                <div className="mb-10 text-dark">
                  <div dangerouslySetInnerHTML={{ __html: aboutDescription}} />
                </div>
                
                {showMission && (
                  <>
                    <h3 className="mb-6 text-2xl font-bold text-dark sm:text-[28px] sm:leading-[40px]">
                      {aboutMissionTitle || "Our Mission"}
                    </h3>
                    <div className="mb-10 text-dark">
                      <div dangerouslySetInnerHTML={{ __html: aboutMissionDescription}} />

                    </div>
                  </>
                )}
                
                {showValues && (
                  <>
                    <h3 className="mb-6 text-2xl font-bold text-dark sm:text-[28px] sm:leading-[40px]">
                      {aboutValuesTitle || "Our Values"}
                    </h3>
                    <div className="mb-10 text-dark">
                      <div dangerouslySetInnerHTML={{ __html: aboutValuesDescription}} />

                    </div>
                  </>
                )}
                
                {bottomImageUrl && (
                  <div className="relative overflow-hidden rounded-lg mb-6">
                    <div className="mx-auto w-full">
                      <img
                        className="h-auto w-full"
                        src={bottomImageUrl}
                        alt="image description"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="w-full px-4 lg:w-4/12">
              {firstSideImageUrl && (
                <div className="relative mb-12 overflow-hidden rounded-[5px] bg-primary">
                  <img
                    className="h-auto w-full"
                    src={firstSideImageUrl}
                    alt="First Side"
                  />
                </div>
              )}
              {secondSideImageUrl && (
                <div className="relative mb-12 overflow-hidden rounded-[5px] bg-primary">
                  <img
                    className="h-auto w-full"
                    src={secondSideImageUrl}
                    alt="Second Side"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;