import React, { useState } from 'react';
import { db, storage } from '../../../firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import DashboardNavbar from '../DashboardNavbar';

function AddCategoryPage() {
  const [category, setCategory] = useState({
    name: '',
    description: '',
    image: null
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image' && files[0]) {
      setCategory({
        ...category,
        [name]: files[0],
      });
    } else {
      setCategory({
        ...category,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { name, description, image } = category;

      // Upload the image to Firebase Storage and get the download URL
      const imageRef = ref(storage, `categories/${name}/${image.name}`);
      await uploadBytes(imageRef, image);
      const imageUrl = await getDownloadURL(imageRef);

      // Create a document in Firestore with the category name as the document ID
      await setDoc(doc(db, 'categories', name), {
        name,
        description,
        imageUrl,
      });

      alert('Category added successfully!');
    } catch (error) {
      console.error('Error adding category:', error);
      alert('Error adding category. Please try again.');
    }
  };

  return (
    <>
    <DashboardNavbar />
    <section className="relative z-20 overflow-hidden bg-white py-12 lg:pt-[80px] border-t border-gray-50">
      <div className="max-w-2xl px-4 sm:px-6">
        <h2 className="mb-4 text-xl font-bold text-dark">Add New Category</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block bg-white text-sm font-medium text-dark">Category Name</label>
            <input
              type="text"
              name="name"
              value={category.name}
              onChange={handleChange}
              required
              className = "mt-1 block bg-white w-full border border-gray-300 rounded-md p-2 shadow-sm focus:ring focus:ring-opacity-50"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm bg-white font-medium text-dark">Description</label>
            <textarea
              name="description"
              value={category.description}
              onChange={handleChange}
              rows={9}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md p-2 shadow-sm focus:ring focus:ring-opacity-50"
            ></textarea>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-dark">Image</label>
            <input
              type="file"
              name="image"
              onChange={handleChange}
              required
              className="mt-1 block w-full border border-gray-300 rounded-md p-2 shadow-sm focus:ring focus:ring-opacity-50"
            />
          </div>
          <button
            type="submit"
            className="px-4 py-2 bg-info-500 text-white rounded-md hover:bg-blue-700"
          >
            Add Category
          </button>
        </form>
      </div>
    </section>
    </>
  );
}

export default AddCategoryPage;