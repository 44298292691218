import React, { useEffect, useState } from "react";
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import FooterSvg from './svg/FooterSvg';
import TwitterSvg from './svg/TwitterSvg';
import FacebookSvg from './svg/FacebookSvg';
import InstagramSvg from './svg/InstagramSvg';
import LinkedinSvg from './svg/LinkedinSvg';
import StarIcon from '@mui/icons-material/Star';
import StarHalfIcon from '@mui/icons-material/StarHalf';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';

const reviews = [
  { name: "Johnny Mac", review: "I have visited and recommended this company for many years...", stars: 5 },
  { name: "aziz hawa", review: "Good place for roofing materials. The Asian gentleman is very polite and helpful", stars: 5 },
  { name: "Mark Sutton", review: "Helpful advice from Dean and Kevin, great product selection at competitive prices too.", stars: 5 },
  { name: "kingdon333", review: "The best roofing company Dean and his team are amazing always helping and giving a good service thanks", stars: 5 },
  { name: "Andrew Jones The VATman Ltd", review: "Shout out to Dean, really helpful chap. Went over and above the call of duty - thank you", stars: 5 },
  { name: "Charan Singh", review: "I heard about UK Roofing supplies from a friend and I was pleasantly surprised by the service I received from them.", stars: 5 },
  { name: "Greg Martin", review: "Dean is a legend. Shame about Ken lol. Only joking... good service by all", stars: 5 },
  { name: "Mark Linehan", review: "Extensive range of products at competitive prices, friendly staff to help you make your purchases.", stars: 5 }
];

function Footer() {
  const [branches, setBranches] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchBranches = async () => {
      const docRef = doc(db, 'homeContent', 'heroSection');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setBranches(docSnap.data().branches || []);
      } else {
        console.log('No such document!');
      }
    };

    fetchBranches();
  }, []);

  useEffect(() => {
    // When the modal opens, prevent body scroll
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      // When the modal closes, re-enable body scroll
      document.body.style.overflow = 'auto';
    }

    // Cleanup function to ensure body scroll is re-enabled if the component is unmounted
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalOpen]);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <footer className="relative z-10 bg-primaryDark pt-[48px]">
      <div className="max-w-screen-7xl mx-auto px-4">
        <div className="flex flex-wrap">
          <div className="w-full px-4 sm:w-1/2 md:w-1/2 lg:w-4/12 xl:w-3/12 mb-10">
            <a href="/" className="mb-6 inline-block max-w-[160px]">
              <img 
                src={`${process.env.PUBLIC_URL}/ukroofingsupplies-logo.jpeg`} 
                className="max-w-full" 
                alt="UK Roofing Supplies Logo" 
                width="160"  
                height="auto" 
              />
            </a>
            <p className="mb-4 max-w-[270px] text-white">
              A leading roofing supply merchant proudly serving the industry since 1977.
            </p>
            
            <div className="flex items-center mt-4">
              <button className="pl-0 pr-3 text-dark hover:text-white" onClick={() => window.location.href = "https://www.facebook.com/UKRoofingSupp"}>
                <FacebookSvg />
              </button>
              <button className="px-3 text-dark hover:text-white" onClick={() => window.location.href = "https://x.com/ukroofingsupp"}>
                <TwitterSvg />
              </button>
              <button className="px-3 text-dark hover:text-white" onClick={() => window.location.href = "https://www.instagram.com/ukroofingsupplies/"}>
                <InstagramSvg />
              </button>
              <button className="px-3 text-dark hover:text-white" onClick={() => window.location.href = "https://www.linkedin.com/company/uk-roofing-&-plastics-supplies-ltd"}>
                <LinkedinSvg />
              </button>
            </div>

            <div className="flex items-center mt-4">
              <span className="text-white mr-2 cursor-pointer underline" onClick={handleOpenModal}>
                Google Reviews
              </span>
              <StarIcon className="text-warning-300" />
              <StarIcon className="text-warning-300" />
              <StarIcon className="text-warning-300" />
              <StarIcon className="text-warning-300" />
              <StarHalfIcon className="text-warning-300" />
            </div>
          </div>
          
          {branches.map((branch, index) => (
            <div key={index} className="w-full px-4 sm:w-1/2 md:w-1/2 lg:w-4/12 xl:w-3/12 mb-10">
              <h2 className="mb-9 text-lg font-semibold text-white">{branch.branch_name}</h2>
              <ul className="text-primary-100 text-sm">
                {branch.phone && (
                  <li className="mb-4">
                    <i className="fas fa-phone-alt"></i> {branch.phone}
                  </li>
                )}
                {branch.email && (
                  <li className="mb-4">
                    <i className="fas fa-envelope"></i> {branch.email}
                  </li>
                )}
                {branch.hours_weekdays && (
                  <li className="mb-4">{branch.hours_weekdays}</li>
                )}
                {branch.hours_weekends && (
                  <li className="mb-4">{branch.hours_weekends}</li>
                )}
                {branch.address && (
                  <li>{branch.address}</li>
                )}
                {branch.branch_name === "Slough Branch" && (
                  <li className="mt-2">
                    <Link to="/privacy-policy" className="underline text-white">
                      Privacy Policy
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          ))}
        </div>
      </div>
      
      <div className="border-t border-[#8890A4] border-opacity-40 py-8">
        <div className="max-w-screen-7xl mx-auto px-4 sm:px-8">
          <div className="flex flex-wrap">
            <div className="w-full px-4 md:px-0 lg:w-1/2">
              <div className="my-1">
                <div className="flex items-center justify-center md:justify-start">
                  <span className="text-sm text-white sm:text-start">
                    Copyrights © 2024 UK Roofing & Plastic Supplies Ltd | Corp No. 1345702 | All rights reserved.
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full px-4 md:px-0 lg:w-1/2">
              <div className="my-1 flex justify-start lg:justify-end">
                <p className="text-dark text-dark">
                  <span className="text-sm text-white sm:text-center">
                    Built with ❤️ by Elias Glyptis @
                  </span>{" "}
                  <a
                    href="https://theaworld.com"
                    rel="noopener noreferrer"
                    target="_blank"
                    className="text-gray-100 hover:underline"
                  >
                    Thea World
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterSvg />

      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="google-reviews-modal"
        aria-describedby="modal-with-hardcoded-google-reviews"
        className="flex justify-center items-center"
       componentsProps={{
          backdrop: {
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0.7)', // Equivalent to Tailwind's bg-opacity-70
            },
          },
        }}
      >
        <div className="bg-white p-6 m-4 rounded-lg max-w-2xl w-full max-h-[80vh] overflow-y-auto relative border-0" style={{ outline: 'none' }}>
          <h2 id="google-reviews-modal" className="text-lg text-dark font-bold mb-4">Customer Reviews</h2>
          {reviews.map((review, index) => (
            <div key={index}
              className="block w-full rounded-lg bg-white p-4 border border-1 border-gray-100 shadow-sm mb-4"
            >
              <div className="flex items-center mb-2">
                <h5 className="text-md font-semibold text-dark">
                  {review.name}
                </h5>
                <div className="flex ml-2">
                  {[...Array(review.stars)].map((_, i) => (
                    <StarIcon key={i} className="text-warning-300 h-5 w-5" />
                  ))}
                </div>
              </div>
              {review.stars < 5 && <StarOutlineIcon className="text-warning-300" />}
              <p className="mb-2 text-dark">
                {review.review}
              </p>
            </div>
          ))}
          <button 
            onClick={handleCloseModal} 
            className="absolute top-4 right-4 text-gray-600 hover:text-gray-800"
          >
            <CloseIcon />
          </button>
        </div>
      </Modal>
    </footer>
  );
}

export default Footer;
