import React, { useState, useEffect } from 'react';
import { db, storage } from '../../../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import DashboardNavbar from '../DashboardNavbar';

function HeroEditPage() {
  const [content, setContent] = useState({
    hero_title: '',
    hero_description: '',
    hero_cta1: '',
    hero_cta1_link: '',
    hero_cta2: '',
    hero_cta2_link: '',
    hero_image: '',
  });
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    const fetchContent = async () => {
      const docRef = doc(db, 'homeContent', 'heroSection');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setContent(docSnap.data());
      } else {
        console.log('No such document!');
      }
    };

    fetchContent();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContent({
      ...content,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const docRef = doc(db, 'homeContent', 'heroSection');

    let updatedImageUrl = content.hero_image;

    // Handle image upload
    if (imageFile) {
      const imageRef = ref(storage, `hero_images/${imageFile.name}`);
      await uploadBytes(imageRef, imageFile);
      updatedImageUrl = await getDownloadURL(imageRef);

      // If there's an existing image, delete it
      if (content.hero_image) {
        const oldImageRef = ref(storage, content.hero_image);
        await deleteObject(oldImageRef);
      }
    }

    const updatedFields = {
      ...content,
      hero_image: updatedImageUrl,
    };

    await updateDoc(docRef, updatedFields);
    alert('Content updated successfully!');
  };

  return (
    <>
      <DashboardNavbar />
      <section className="relative z-20 overflow-hidden bg-white py-12 lg:pt-[80px] border-t border-gray-50">
        <div className="max-w-2xl px-4 sm:px-6">
          <h2 className="mb-4 text-xl font-bold text-dark">Edit Hero Section</h2>
          <form onSubmit={handleSubmit}>
            <div className="grid gap-4 sm:grid-cols-1 sm:gap-6">
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-dark">Hero Title</label>
                <input
                  type="text"
                  name="hero_title"
                  value={content.hero_title}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Type hero title"
                  required
                />
              </div>
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-dark">Hero Description</label>
                <input
                  type="text"
                  name="hero_description"
                  value={content.hero_description}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Type hero description"
                  required
                />
              </div>
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-dark">Hero CTA button 1</label>
                <input
                  type="text"
                  name="hero_cta1"
                  value={content.hero_cta1}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Type hero CTA 1"
                  required
                />
              </div>
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-dark">Hero Button eg: /products</label>
                <input
                  type="text"
                  name="hero_cta1_link"
                  value={content.hero_cta1_link}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Type the link"
                  required
                />
              </div>
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-dark">Hero CTA button 2</label>
                <input
                  type="text"
                  name="hero_cta2"
                  value={content.hero_cta2}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Type hero button text"
                  required
                />
              </div>
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-dark">Hero Button 2 eg: /branches</label>
                <input
                  type="text"
                  name="hero_cta2_link"
                  value={content.hero_cta2_link}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Type the link"
                  required
                />
              </div>
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-dark">Hero Image</label>
                <input
                  type="file"
                  onChange={handleFileChange}
                  className="block w-full text-sm text-dark border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600"
                />
                {content.hero_image && (
                  <img src={content.hero_image} alt="Hero" className="mt-4 max-w-full h-auto" />
                )}
              </div>
            </div>

            <button
              type="submit"
              className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-info-500 rounded-lg"
            >
              Update Hero Section
            </button>
          </form>
        </div>
      </section>
    </>
  );
}

export default HeroEditPage;
