import React, { useState, useEffect } from 'react';
import { db, storage } from '../../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import DashboardNavbar from './DashboardNavbar';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function EditAboutPage() {
  const [aboutHeroTitle, setAboutHeroTitle] = useState('');
  const [aboutHeroDescription, setAboutHeroDescription] = useState('');
  const [aboutTitle, setAboutTitle] = useState('');
  const [aboutDescription, setAboutDescription] = useState('');
  const [missionTitle, setMissionTitle] = useState('');
  const [missionDescription, setMissionDescription] = useState('');
  const [showMission, setShowMission] = useState(true);
  const [valuesTitle, setValuesTitle] = useState('');
  const [valuesDescription, setValuesDescription] = useState('');
  const [showValues, setShowValues] = useState(true);
  const [firstSideImageUrl, setFirstSideImageUrl] = useState('');
  const [secondSideImageUrl, setSecondSideImageUrl] = useState('');
  const [bottomImageUrl, setBottomImageUrl] = useState('');
  const [showFirstSideImage, setShowFirstSideImage] = useState(true);
  const [showSecondSideImage, setShowSecondSideImage] = useState(true);
  const [showBottomImage, setShowBottomImage] = useState(true);
  const [firstSideImageFile, setFirstSideImageFile] = useState(null);
  const [secondSideImageFile, setSecondSideImageFile] = useState(null);
  const [bottomImageFile, setBottomImageFile] = useState(null);
  const [heroImageUrl, setHeroImageUrl] = useState('');
  const [heroImageFile, setHeroImageFile] = useState(null);


  useEffect(() => {
    const fetchContent = async () => {
      const docRef = doc(db, 'homeContent', 'aboutSection');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setAboutHeroTitle(data.aboutHeroTitle);
        setAboutHeroDescription(data.aboutHeroDescription);
        setHeroImageUrl(data.heroImageUrl);
        setAboutTitle(data.aboutTitle);
        setAboutDescription(data.aboutDescription);
        setMissionTitle(data.aboutMissionTitle);
        setMissionDescription(data.aboutMissionDescription);
        setShowMission(data.showMission !== false);  // Defaults to true
        setValuesTitle(data.aboutValuesTitle);
        setValuesDescription(data.aboutValuesDescription);
        setShowValues(data.showValues !== false); // Defaults to true
        setFirstSideImageUrl(data.firstSideImageUrl);
        setSecondSideImageUrl(data.secondSideImageUrl);
        setBottomImageUrl(data.bottomImageUrl);
        setShowFirstSideImage(data.showFirstSideImage !== false);
        setShowSecondSideImage(data.showSecondSideImage !== false);
        setShowBottomImage(data.showBottomImage !== false);
      } else {
        console.log('No such document!');
      }
    };

    fetchContent();
  }, []);

  const handleImageChange = (setter, setFile) => (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setter(URL.createObjectURL(e.target.files[0]));
    }
  };

  const handleImageDelete = async (imageUrl, setter) => {
    if (imageUrl) {
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);
      setter('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const uploadImage = async (imageFile, imageUrl, path) => {
      if (imageFile) {
        const imageRef = ref(storage, `${path}/${imageFile.name}`);
        await uploadBytes(imageRef, imageFile);
        return await getDownloadURL(imageRef);
      }
      return imageUrl;
    };

    const updatedHeroImageUrl = await uploadImage(heroImageFile, heroImageUrl, 'about');
    const updatedFirstSideImageUrl = await uploadImage(firstSideImageFile, firstSideImageUrl, 'about');
    const updatedSecondSideImageUrl = await uploadImage(secondSideImageFile, secondSideImageUrl, 'about');
    const updatedBottomImageUrl = await uploadImage(bottomImageFile, bottomImageUrl, 'about');

    const docRef = doc(db, 'homeContent', 'aboutSection');
    await updateDoc(docRef, {
      aboutHeroTitle,
      aboutHeroDescription,
      heroImageUrl: updatedHeroImageUrl,
      aboutTitle,
      aboutDescription,
      aboutMissionTitle: missionTitle,
      aboutMissionDescription: missionDescription,
      showMission,
      aboutValuesTitle: valuesTitle,
      aboutValuesDescription: valuesDescription,
      showValues,
      firstSideImageUrl: updatedFirstSideImageUrl,
      secondSideImageUrl: updatedSecondSideImageUrl,
      bottomImageUrl: updatedBottomImageUrl,
      showFirstSideImage,
      showSecondSideImage,
      showBottomImage,
    });

    alert('About page content updated successfully!');
  };


  return (
    <>
      <DashboardNavbar />
      <section className="relative z-20 overflow-hidden bg-white py-12 lg:pt-[80px] border-t border-gray-50">
        <div className="max-w-2xl px-4 sm:px-6">
          <h2 className="text-xl font-bold text-dark">Edit About Page</h2>
          <form onSubmit={handleSubmit} className="mt-6">
            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-dark">Hero Title</label>
              <input
                type="text"
                value={aboutHeroTitle}
                onChange={(e) => setAboutHeroTitle(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg block w-full p-2.5"
              />
            </div>
           
            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-gray-900">Hero Description</label>
              <textarea
                value={aboutHeroDescription}
                onChange={(e) => setAboutHeroDescription(e.target.value)}
                className="border border-gray-300 text-dark text-sm rounded-lg block w-full p-2.5"
                rows="5" 
              />
            </div>
            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-dark">Hero Image</label>
              {heroImageUrl && (
                <div className="mb-4">
                  <img src={heroImageUrl} alt="Hero" className="w-full h-64 object-cover rounded-t-lg" />
                  <button
                    type="button"
                    onClick={() => handleImageDelete(heroImageUrl, setHeroImageUrl)}
                    className="mt-2 text-sm text-red-600 hover:underline"
                  >
                    Delete Image
                  </button>
                </div>
              )}
              <input
                type="file"
                onChange={handleImageChange(setHeroImageUrl, setHeroImageFile)}
                className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg block w-full p-2.5 cursor-pointer bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600"
              />
            </div>

            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-dark">About Description</label>
              <ReactQuill
                value={aboutDescription}
                onChange={setAboutDescription}
                className="border-none text-dark text-sm block w-full "
                style={{ height: '200px' }}
              />
            </div>
            <div className="mb-6">
              <label className="block mb-2 mt-[70px] text-sm font-medium text-dark">Mission Title</label>
              <input
                type="text"
                value={missionTitle}
                onChange={(e) => setMissionTitle(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg block w-full p-2.5"
              />
              <label className="block mb-2 mt-6 text-sm font-medium text-dark">Mission Description</label>
              <ReactQuill
                value={missionDescription}
                onChange={setMissionDescription}
                className="border-none text-dark text-sm block w-full "
                style={{ height: '200px' }}
              />
              <label className="inline-flex items-center mt-12">
                <input
                  type="checkbox"
                  checked={showMission}
                  onChange={(e) => setShowMission(e.target.checked)}
                  className="text-dark border-gray-300 rounded focus:ring-primary-600"
                />
                <span className="ml-2 text-sm font-medium text-dark">Show Mission Section</span>
              </label>
            </div>
            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-dark">Values Title</label>
              <input
                type="text"
                value={valuesTitle}
                onChange={(e) => setValuesTitle(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg block w-full p-2.5"
              />
              <label className="block mb-2 mt-6 text-sm font-medium text-dark">Values Description</label>
              <ReactQuill
                value={valuesDescription}
                onChange={setValuesDescription}
                className="border-none text-dark text-sm block w-full "
                style={{ height: '200px' }}
              />
              <label className="inline-flex items-center mt-12">
                <input
                  type="checkbox"
                  checked={showValues}
                  onChange={(e) => setShowValues(e.target.checked)}
                  className="text-dark border-gray-300 rounded focus:ring-primary-600"
                />
                <span className="ml-2 text-sm font-medium text-dark">Show Values Section</span>
              </label>
            </div>
            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-dark">First Side Image</label>
              {firstSideImageUrl && showFirstSideImage && (
                <div className="mb-4">
                  <img src={firstSideImageUrl} alt="First Side" className="w-full h-64 object-cover rounded-t-lg" />
                  <button
                    type="button"
                    onClick={() => handleImageDelete(firstSideImageUrl, setFirstSideImageUrl)}
                    className="mt-2 text-sm text-red-600 hover:underline"
                  >
                    Delete Image
                  </button>
                </div>
              )}
              <input
                type="file"
                onChange={handleImageChange(setFirstSideImageUrl, setFirstSideImageFile)}
                className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg block w-full p-2.5 cursor-pointer bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600"
              />
              <label className="inline-flex items-center mt-2">
                <input
                  type="checkbox"
                  checked={showFirstSideImage}
                  onChange={(e) => setShowFirstSideImage(e.target.checked)}
                  className="text-dark border-gray-300 rounded focus:ring-primary-600"
                />
                <span className="ml-2 text-sm font-medium text-dark">Show First Side Image</span>
              </label>
            </div>
            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-dark">Second Side Image</label>
              
              {secondSideImageUrl && showSecondSideImage && (
                <div className="mb-4">
                  <img src={secondSideImageUrl} alt="Second Side" className="w-full h-64 object-cover rounded-t-lg" />
                  <button
                    type="button"
                    onClick={() => handleImageDelete(secondSideImageUrl, setSecondSideImageUrl)}
                    className="mt-2 text-sm text-red-600 hover:underline"
                  >
                    Delete Image
                  </button>
                </div>
              )}
              <input
                type="file"
                onChange={handleImageChange(setSecondSideImageUrl, setSecondSideImageFile)}
                className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg block w-full p-2.5 cursor-pointer bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600"
              />
              <label className="inline-flex items-center mt-2">
                <input
                  type="checkbox"
                  checked={showSecondSideImage}
                  onChange={(e) => setShowSecondSideImage(e.target.checked)}
                  className="text-dark border-gray-300 rounded focus:ring-primary-600"
                />
                <span className="ml-2 text-sm font-medium text-dark">Show Second Side Image</span>
              </label>
            </div>
            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-dark">Bottom Image</label>
              {bottomImageUrl && showBottomImage && (
                <div className="mb-4">
                  <img src={bottomImageUrl} alt="Bottom" className="w-full h-64 object-cover rounded-t-lg" />
                  <button
                    type="button"
                    onClick={() => handleImageDelete(bottomImageUrl, setBottomImageUrl)}
                    className="mt-2 text-sm text-red-600 hover:underline"
                  >
                    Delete Image
                  </button>
                </div>
              )}
              
              <input
                type="file"
                onChange={handleImageChange(setBottomImageUrl, setBottomImageFile)}
                className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg block w-full p-2.5 cursor-pointer bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600"
              />
              <label className="inline-flex items-center mt-2">
                <input
                  type="checkbox"
                  checked={showBottomImage}
                  onChange={(e) => setShowBottomImage(e.target.checked)}
                  className="text-dark border-gray-300 rounded focus:ring-primary-600"
                />
                <span className="ml-2 text-sm font-medium text-dark">Show Bottom Image</span>
              </label>
            </div>
            <button
              type="submit"
              className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-info-500 rounded-lg"
            >
              Update About Page
            </button>
          </form>
        </div>
      </section>
    </>
  );
}

export default EditAboutPage;