import React, { useState, useEffect } from 'react';
import { db, storage } from '../../../firebaseConfig';
import { doc, getDoc, updateDoc, collection, addDoc, deleteDoc, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import DashboardNavbar from '../DashboardNavbar';

function ServicesEditPage() {
  const [content, setContent] = useState({
    home_services_title: '',
    home_services_description: ''
  });

  const [services, setServices] = useState([]);
  const [newService, setNewService] = useState({
    service_title: '',
    service_description: '',
    service_image: null
  });

  useEffect(() => {
    const fetchContent = async () => {
      const docRef = doc(db, 'homeContent', 'heroSection');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setContent(docSnap.data());
      } else {
        console.log('No such document!');
      }
    };

    const fetchServices = async () => {
      const querySnapshot = await getDocs(collection(db, 'homeContent', 'heroSection', 'services'));
      const servicesData = [];
      querySnapshot.forEach((doc) => {
        servicesData.push({ id: doc.id, ...doc.data() });
      });
      setServices(servicesData);
    };

    fetchContent();
    fetchServices();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContent({
      ...content,
      [name]: value,
    });
  };

  const handleServiceChange = (e, id) => {
    const { name, value } = e.target;
    setServices((prevServices) =>
      prevServices.map((service) =>
        service.id === id ? { ...service, [name]: value } : service
      )
    );
  };

  const handleServiceFileChange = async (e, id) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = await uploadFile(file, `homeContent/heroSection/services/${file.name}`);
      setServices((prevServices) =>
        prevServices.map((service) =>
          service.id === id ? { ...service, service_image: imageUrl } : service
        )
      );
    }
  };

  const updateService = async (id) => {
    const serviceRef = doc(db, 'homeContent', 'heroSection', 'services', id);
    const serviceToUpdate = services.find((service) => service.id === id);
    await updateDoc(serviceRef, serviceToUpdate);
    alert('Service updated successfully!');
  };

  const addService = async (e) => {
    e.preventDefault();
    const serviceRef = collection(db, 'homeContent', 'heroSection', 'services');
    const imageUrl = await uploadFile(newService.service_image, `homeContent/heroSection/services/${newService.service_image.name}`);
    await addDoc(serviceRef, { ...newService, service_image: imageUrl });
    setNewService({
      service_title: '',
      service_description: '',
      service_image: null
    });
    alert('Service added successfully!');
  };

  const deleteService = async (id) => {
    await deleteDoc(doc(db, 'homeContent', 'heroSection', 'services', id));
    setServices(services.filter((service) => service.id !== id));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const docRef = doc(db, 'homeContent', 'heroSection');

    const updatedFields = {};
    for (const key in content) {
      if (content[key] !== '' && content[key] !== null) {
        updatedFields[key] = content[key];
      }
    }

    await updateDoc(docRef, updatedFields);
    alert('Content updated successfully!');
  };

  const uploadFile = async (file, path) => {
    const fileRef = ref(storage, path);
    await uploadBytes(fileRef, file);
    return await getDownloadURL(fileRef);
  };

  return (
    <>
      <DashboardNavbar />
      <section className="relative z-20 overflow-hidden bg-white py-12 lg:pt-[80px] border-t border-gray-50">
        <div className="max-w-2xl px-4 sm:px-6">
          <h2 className="mb-4 text-xl font-bold text-dark">Edit Services Section</h2>
          <form onSubmit={handleSubmit}>
            <div className="grid gap-4 sm:grid-cols-1 sm:gap-6">
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-dark">Services Title</label>
                <input
                  type="text"
                  name="home_services_title"
                  value={content.home_services_title}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Services title"
                />
              </div>
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-dark">Services Description</label>
                <textarea
                  name="home_services_description"
                  value={content.home_services_description}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Services description"
                  rows="5"
                ></textarea>
              </div>

              {/* Editable Service Details */}
              <div className="sm:col-span-1">
                <h3 className="block mb-2 text-lg font-medium text-dark">Manage Services</h3>
                {services.map((service) => (
                  <div key={service.id} className="mb-4 p-4 border rounded-md">
                    <label className="block mb-2 text-sm font-medium text-dark">Service Title</label>
                    <input
                      type="text"
                      name="service_title"
                      value={service.service_title}
                      onChange={(e) => handleServiceChange(e, service.id)}
                      className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mb-2"
                      placeholder="Service title"
                    />

                    <label className="block mb-2 text-sm font-medium text-dark">Service Description</label>
                    <textarea
                      name="service_description"
                      value={service.service_description}
                      onChange={(e) => handleServiceChange(e, service.id)}
                      className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 mb-2"
                      placeholder="Service description"
                      rows="3"
                    ></textarea>

                    <label className="block mb-2 text-sm font-medium text-dark">Service Image</label>
                    <input
                      type="file"
                      name="service_image"
                      onChange={(e) => handleServiceFileChange(e, service.id)}
                      className="block w-full text-sm text-dark border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600 mb-2"
                    />
                    <img src={service.service_image} className="h-auto max-w-full rounded-lg object-cover mt-2" alt="Service" />

                    <button
                      type="button"
                      onClick={() => updateService(service.id)}
                      className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-info-500 rounded-lg"
                    >
                      Update Service
                    </button>

                    <button
                      type="button"
                      onClick={() => deleteService(service.id)}
                      className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-red-500 rounded-lg ml-2"
                    >
                      Delete Service
                    </button>
                  </div>
                ))}

                <h4 className="mt-4 text-lg font-medium text-dark">Add New Service</h4>
                <div className="sm:col-span-1">
                  <label className="block mb-2 text-sm font-medium text-dark">Service Title</label>
                  <input
                    type="text"
                    name="service_title"
                    value={newService.service_title}
                    onChange={handleServiceChange}
                    className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    placeholder="Service title"
                  />
                </div>
                <div className="sm:col-span-1">
                  <label className="block mb-2 text-sm font-medium text-dark">Service Description</label>
                  <textarea
                    name="service_description"
                    value={newService.service_description}
                    onChange={handleServiceChange}
                    className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    placeholder="Service description"
                    rows="3"
                  ></textarea>
                </div>
                <div className="sm:col-span-1">
                  <label className="block mb-2 text-sm font-medium text-dark">Service Image</label>
                  <input
                    type="file"
                    name="service_image"
                    onChange={handleServiceFileChange}
                    className="block w-full text-sm text-dark border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600"
                  />
                </div>
                <button
                  type="button"
                  onClick={addService}
                  className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-success-500 rounded-lg"
                >
                  Add Service
                </button>
              </div>
            </div>

            <button type="submit" className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-info-500 rounded-lg">
              Update Services
            </button>
          </form>
        </div>
      </section>
    </>
  );
}

export default ServicesEditPage;
