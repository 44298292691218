import React, { useState, useEffect } from 'react';
import { db, storage } from '../../../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { useParams } from 'react-router-dom';
import DashboardNavbar from '../DashboardNavbar';


function EditCategoryPage() {
  const { id } = useParams();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const fetchCategory = async () => {
      const docRef = doc(db, 'categories', id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const categoryData = docSnap.data();
        setName(categoryData.name);
        setDescription(categoryData.description);
        setImageUrl(categoryData.imageUrl);
      } else {
        console.log('No such document!');
      }
    };

    fetchCategory();
  }, [id]);

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
      setImageFile(e.target.files[0]);
    }
  };

  const handleDeleteImage = async () => {
    if (imageUrl) {
      const imageRef = ref(storage, imageUrl);
      await deleteObject(imageRef);
      setImageUrl('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name.length < 3 || name.length > 50) {
      alert('Name must be between 3 and 50 characters');
      return;
    }

    if (description.length < 50 || description.length > 600) {
      alert('Description must be between 50 and 600 characters');
      return;
    }

    let updatedImageUrl = imageUrl;

    if (imageFile) {
      const imageRef = ref(storage, `categories/${imageFile.name}`);
      await uploadBytes(imageRef, imageFile);
      updatedImageUrl = await getDownloadURL(imageRef);
    }

    const docRef = doc(db, 'categories', id);
    await updateDoc(docRef, {
      name,
      description,
      imageUrl: updatedImageUrl,
    });

    alert('Category updated successfully!');
  };

  return (
    <>
    <DashboardNavbar />
    <section className="relative z-20 overflow-hidden bg-white py-12 lg:pt-[80px] border-t border-gray-50">
      <div className="max-w-2xl px-4 sm:px-6">
        <h2 className="mb-4 text-xl font-bold text-dark">Edit Category: {name}</h2>
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4 sm:grid-cols-1 sm:gap-6">
            <div className="sm:col-span-1">
              <label className="block mb-2 text-sm font-medium text-dark">Name</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Category Name"
                required
              />
            </div>
            <div className="sm:col-span-1">
              <label className="block mb-2 text-sm font-medium text-dark">Description</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={9}
                className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Category Description"
                required
              ></textarea>
            </div>
            <div className="col-span-1">
              <label className="block mb-2 text-sm font-medium text-dark">Image</label>
              {imageUrl && (
                <div className="mb-4">
                  <img src={imageUrl} alt="Category" className="w-full h-64 object-cover rounded-t-lg" />
                  <button
                    type="button"
                    onClick={handleDeleteImage}
                    className="mt-2 text-sm text-red-600 hover:underline"
                  >
                    Delete Image
                  </button>
                </div>
              )}
              <input
                type="file"
                onChange={handleFileChange}
                className="block w-full text-sm text-dark border border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600"
              />
            </div>
          </div>
          <button
            type="submit"
            className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-info-500 rounded-lg"
          >
            Update Category
          </button>
        </form>
      </div>
    </section>
    </>
  );
}

export default EditCategoryPage;