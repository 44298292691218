import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import Hero from '../components/Hero';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import { Link } from 'react-router-dom';

function PublicProductsPage() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [productsHeroTitle, setProductsHeroTitle] = useState('');
  const [productsHeroDescription, setProductsHeroDescription] = useState('');
  const [productsImageUrl, setProductsImageUrl] = useState('');

  // Modal state for category and tag
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);

  useEffect(() => {
    const fetchProductsData = async () => {
      const productsSnapshot = await getDocs(collection(db, 'products'));
      const categoriesSnapshot = await getDocs(collection(db, 'categories'));

      const productsData = [];
      const tagsData = new Set();

      productsSnapshot.forEach((doc) => {
        const productData = doc.data();
        productsData.push({ id: doc.id, ...productData });
        tagsData.add(productData.tag);
      });

      const categoriesData = [];
      categoriesSnapshot.forEach((doc) => {
        categoriesData.push(doc.data().name);
      });

      setProducts(productsData);
      setCategories(categoriesData);
      setTags(Array.from(tagsData));
    };

    fetchProductsData();
  }, []);

  useEffect(() => {
    const fetchHeroContent = async () => {
      const docRef = doc(db, 'homeContent', 'productsPage');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setProductsHeroTitle(data.products_page_hero_title);
        setProductsHeroDescription(data.products_page_hero_description);
        setProductsImageUrl(data.productsImageUrl);
      } else {
        console.log('No such document!');
      }
    };

    fetchHeroContent();
  }, []);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setIsCategoryModalOpen(false);
  };

  const handleTagChange = (tag) => {
    setSelectedTag(tag);
    setIsTagModalOpen(false);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const clearFilters = () => {
    setSelectedCategory('');
    setSelectedTag('');
    setSearchTerm('');
  };

  const filteredProducts = products.filter((product) => {
    return (
      (!selectedCategory || product.categoryName === selectedCategory) &&
      (!selectedTag || product.tag === selectedTag) &&
      (product.title?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.categoryName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.tag?.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  });

  const filtersApplied = selectedCategory || selectedTag || searchTerm;

  useEffect(() => {
    if (isCategoryModalOpen || isTagModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isCategoryModalOpen, isTagModalOpen]);

  return (
    <div>
      {(isCategoryModalOpen || isTagModalOpen) && (
        <div className="fixed inset-0 z-40 bg-black-900 bg-opacity-50"></div>
      )}

      <Hero 
        className="products-hero-background-gradient"
        title={productsHeroTitle}  // Dynamically setting the hero title
        subtitle="Products"
        description={productsHeroDescription}  // Dynamically setting the hero description
        backgroundImage={productsImageUrl} // Pass the background image URL
      />

      <section className="relative z-20 overflow-hidden bg-white pt-8 lg:pt-[80px]">
        <div className="max-w-screen-2xl mx-auto px-8 sm:px-6">
          <div className="grid grid-cols-1">
            <ul className="flex py-2 px-2 border rounded-full bg-primary border-primary overflow-x-auto">
              {categories.map((category, index) => (
                <li key={index} className="mr-2">
                  <Link
                    className={`text-center block py-2 px-4 whitespace-nowrap ${selectedCategory === category ? 'bg-primaryDark rounded-full text-white font-semibold' : 'bg-transparent text-white font-semibold'}`}
                    to="#"
                    onClick={() => handleCategoryChange(category)}
                  >
                    {category}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      <section className="relative z-20 overflow-hidden bg-white py-8 lg:pt-[60px]">
        <div className="max-w-screen-2xl mx-auto px-8 sm:px-6">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-4 mb-4 px-1">
            <div className="col-span-1 md:col-span-3 lg:col-span-1 pr-4">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchChange}
                className="w-full lg:w-96 bg-transparent text-dark placeholder:text-body-color/60 border-0 border-b pb-3 focus:border-primary focus:outline-none"
                placeholder="Search products"
              />
            </div>
            <div className="col-span-1 lg:text-right">
              <Link
                to="#"
                onClick={() => setIsCategoryModalOpen(true)}
                className={`text-sm inline-flex items-center ${selectedCategory ? 'text-primaryDark font-semibold' : 'text-dark'}`}
              >
                {selectedCategory ? `Filtered by: ${selectedCategory}` : 'Filter by Category'}
                {selectedCategory ? <CheckIcon className="ml-2 mt-[-5px]" /> : <AddIcon className="ml-2" />}
              </Link>
            </div>
            <div className="col-span-1 lg:text-right">
              <Link
                to="#"
                onClick={() => setIsTagModalOpen(true)}
                className={`text-sm inline-flex items-center ${selectedTag ? 'text-primaryDark font-semibold' : 'text-dark'}`}
              >
                {selectedTag ? `Filtered by: ${selectedTag}` : 'Filter by Tag'}
                {selectedTag ? <CheckIcon className="ml-2" /> : <AddIcon className="ml-2" />}
              </Link>
            </div>
          </div>
          {filtersApplied && (
            <div className="w-full mb-4 px-2">
              <button
                onClick={clearFilters}
                className="text-primary underline"
              >
                Clear All Filters
              </button>
            </div>
          )}

          <div className="grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-8">
            {filteredProducts.map((product) => (
              <div key={product.id} className="max-full rounded overflow-hidden shadow-lg flex flex-col">
                <img className="w-full h-64 object-cover rounded-t-lg" src={product.imageUrl} alt={product.title} />
                <div className="px-6 py-4 flex-grow">
                  <span className="inline-block bg-primary rounded-full text-white px-3 py-1 text-sm mb-2 font-medium"> {product.categoryName} </span>
                  <div className="font-bold text-dark text-lg my-2">{product.title}</div>
                  <p className="text-dark">
                    {product.description}
                  </p>
                </div>
                <div className="px-6 pt-4 pb-2">
                  <span className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm text-dark mr-2 mb-2">#{product.tag}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {isCategoryModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center px-4 sm:px-0">
          <div className="relative w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow max-h-[80vh] w-full flex flex-col">
              <div className="flex items-center justify-between p-4 border-b rounded-t">
                <h3 className="text-lg font-semibold text-dark">Filter by category</h3>
                <IconButton
                  aria-label="close"
                  onClick={() => setIsCategoryModalOpen(false)}
                  size="small"
                  sx={{ color: 'gray' }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="overflow-y-auto p-4 pt-0 flex-grow" style={{ maxHeight: 'calc(80vh - 4rem)' }}>
                <ul className="mt-6 space-y-2">
                  {categories.map((category, index) => (
                    <li key={index}>
                      <a
                        onClick={() => handleCategoryChange(category)}
                        className="block rounded-lg bg-gray-50 px-4 py-4 text-sm font-medium text-dark cursor-pointer"
                      >
                        {category}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}

      {isTagModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center px-4 sm:px-0">
          <div className="relative w-full max-w-md max-h-full">
            <div className="relative bg-white rounded-lg shadow max-h-[80vh] w-full flex flex-col">
              <div className="flex items-center justify-between p-4 mb-4 border-b rounded-t">
                <h3 className="text-lg font-semibold text-dark">Select Tag</h3>
                <IconButton
                  aria-label="close"
                  onClick={() => setIsTagModalOpen(false)}
                  size="small"
                  sx={{ color: 'gray' }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              <div className="overflow-y-auto p-4 pt-0 flex-grow" style={{ maxHeight: 'calc(80vh - 4rem)' }}>
                <ul className="space-y-2">
                  {tags.map((tag, index) => (
                    <li key={index}>
                      <button
                        onClick={() => handleTagChange(tag)}
                        className="inline-block bg-gray-100 rounded-full px-3 py-1 text-sm text-dark mr-2 mb-2"
                      >
                        #{tag}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PublicProductsPage;
