import React, { useState, useEffect } from 'react';
import { db } from '../../../firebaseConfig';
import { doc, getDoc, updateDoc, collection, addDoc, deleteDoc, getDocs } from 'firebase/firestore';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import DashboardNavbar from '../DashboardNavbar';



function FaqEditPage() {
  const [content, setContent] = useState({
    faq_title: '',
    faq_description: ''
  });

  const [faqs, setFaqs] = useState([]);
  const [newFaq, setNewFaq] = useState({
    faq_question_title: '',
    faq_question_description: ''
  });

  useEffect(() => {
    const fetchContent = async () => {
      const docRef = doc(db, 'homeContent', 'heroSection');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setContent(docSnap.data());
      } else {
        console.log('No such document!');
      }
    };

    const fetchFaqs = async () => {
      const querySnapshot = await getDocs(collection(db, 'homeContent', 'heroSection', 'faqs'));
      const faqsData = [];
      querySnapshot.forEach((doc) => {
        faqsData.push({ id: doc.id, ...doc.data() });
      });
      setFaqs(faqsData);
    };

    fetchContent();
    fetchFaqs();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContent({
      ...content,
      [name]: value,
    });
  };

  const handleEditorChange = (name, value) => {
    setContent({
      ...content,
      [name]: value,
    });
  };

  const handleFaqChange = (e) => {
    const { name, value } = e.target;
    setNewFaq({
      ...newFaq,
      [name]: value,
    });
  };

  const addFaq = async (e) => {
    e.preventDefault();
    const faqRef = collection(db, 'homeContent', 'heroSection', 'faqs');
    await addDoc(faqRef, newFaq);
    setNewFaq({
      faq_question_title: '',
      faq_question_description: ''
    });
    alert('FAQ added successfully!');
  };

  const deleteFaq = async (id) => {
    await deleteDoc(doc(db, 'homeContent', 'heroSection', 'faqs', id));
    setFaqs(faqs.filter((faq) => faq.id !== id));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const docRef = doc(db, 'homeContent', 'heroSection');

    const updatedFields = {};
    for (const key in content) {
      if (content[key] !== '' && content[key] !== null) {
        updatedFields[key] = content[key];
      }
    }

    await updateDoc(docRef, updatedFields);
    alert('Content updated successfully!');
  };

  return (
    <>
      <DashboardNavbar />
      <section className="relative z-20 overflow-hidden bg-white py-12 lg:pt-[80px] border-t border-gray-50">
        <div className="max-w-2xl px-4 sm:px-6">
          <h2 className="mb-4 text-xl font-bold text-dark">Edit FAQ Section</h2>
          <form onSubmit={handleSubmit}>
            <div className="grid gap-4 sm:grid-cols-1 sm:gap-6">
              
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-gray-900">FAQ Title</label>
                <input
                  type="text"
                  name="faq_title"
                  value={content.faq_title}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="FAQ title"
                />
              </div>
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-gray-900">FAQ Description</label>
                <ReactQuill
                  value={content.faq_description}
                  onChange={(value) => handleEditorChange('faq_description', value)}
                  className="bg-gray-50 border-none text-gray-900 text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                />
              </div>
              <div className="sm:col-span-1">
                <h3 className="block mb-2 text-lg font-medium text-gray-900">Manage FAQs</h3>
                {faqs.map((faq, index) => (
                  <div key={index} className="mb-4 p-4 border rounded-md">
                    <h4 className="font-bold">{faq.faq_question_title}</h4>
                    <p>{faq.faq_question_description}</p>
                    <button
                      type="button"
                      onClick={() => deleteFaq(faq.id)}
                      className="mt-2 text-sm text-red-600 hover:underline"
                    >
                      Delete
                    </button>
                  </div>
                ))}
                <h4 className="mt-4 text-lg font-medium text-gray-900">Add New FAQ</h4>
                <div className="sm:col-span-1">
                  <label className="block mb-2 text-sm font-medium text-gray-900">Question Title</label>
                  <input
                    type="text"
                    name="faq_question_title"
                    value={newFaq.faq_question_title}
                    onChange={handleFaqChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    placeholder="Question title"
                  />
                </div>
                <div className="sm:col-span-1">
                  <label className="block mb-2 text-sm font-medium text-gray-900">Question Description</label>
                  <textarea
                    name="faq_question_description"
                    value={newFaq.faq_question_description}
                    onChange={handleFaqChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    placeholder="Question description"
                    rows="3"
                  ></textarea>
                </div>
                <button
                  type="button"
                  onClick={addFaq}
                  className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-success-500 rounded-lg focus:ring-4 focus:ring-success-200 hover:bg-primary-800"
                >
                  Add FAQ
                </button>
              </div>
            </div>

            <button type="submit" className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-primary-500 rounded-lg focus:ring-4 focus:ring-primary-200 hover:bg-primary-800">
              Update FAQ
            </button>
          </form>
        </div>
      </section>
    </>
  );
}

export default FaqEditPage;