import React from 'react';

const Hero = ({ className, title, subtitle, description, backgroundImage }) => {
  return (
    <section
      className={className}
      style={{
        backgroundImage: `linear-gradient(45deg, #152235 0%, rgba(21, 34, 53, 0.2) 100%), url(${backgroundImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }}
    >
      <div className="max-w-screen-2xl mx-auto">
        <div className="w-full py-[150px] px-8 relative">
          <div className="flex">
            <div className="w-full">
              <div className="text-start">
                <h2 className="mb-3 text-2xl sm:text-3xl font-bold text-white">{title}</h2>
                <p className="text-white">
                  {description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;