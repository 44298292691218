import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import ContactInfo from '../components/ContactInfo';
import ContactForm from '../components/ContactForm';
import BranchTabs from '../components/BranchTabs';
import Hero from '../components/Hero';

function Contact() {
  const [heroData, setHeroData] = useState({
    title: 'Send us a message',
    subtitle: 'Contact',
    imageUrl: '',
  });

  useEffect(() => {
    const fetchHeroData = async () => {
      const docRef = doc(db, 'homeContent', 'contactPage');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setHeroData({
          title: data.contact_page_hero_title,
          subtitle: data.contact_page_hero_description,
          imageUrl: data.contactImageUrl,
        });
      } else {
        console.log('No such document!');
      }
    };

    fetchHeroData();
  }, []);

  return (
    <>  
      <Hero 
        className=""
        title={heroData.title}
        description={heroData.subtitle}
        backgroundImage={heroData.imageUrl} // Pass the image URL to the Hero component
      />
     
      <section id="branches" className="bg-white py-6 lg:py-[40px] border-t border-gray-50">
        <div className="max-w-screen-2xl mx-auto px-8 sm:px-6">
          <div className="mt-12 flex flex-wrap items-center">
            <div className="w-full lg:w-2/3">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
