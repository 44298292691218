import React, { useState, useEffect } from 'react';
import { db, storage } from '../../../firebaseConfig'; 
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 
import DashboardNavbar from '../DashboardNavbar';

function AboutEditPage() {
  const [content, setContent] = useState({
    about_title: '',
    about_description: '',
    about_cta: '',
    about_cta_link: '', // New state for the CTA link
    about_images: []
  });

  useEffect(() => {
    const fetchContent = async () => {
      const docRef = doc(db, 'homeContent', 'heroSection');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setContent(docSnap.data());
      } else {
        console.log('No such document!');
      }
    };

    fetchContent();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setContent({
      ...content,
      [name]: value,
    });
  };

  const handleEditorChange = (name, value) => {
    setContent({
      ...content,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        const newAboutImages = [...content.about_images];
        newAboutImages[parseInt(name.split('_')[2], 10)] = files[0];
        setContent({
          ...content,
          about_images: newAboutImages,
        });
      };
      fileReader.readAsDataURL(files[0]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const docRef = doc(db, 'homeContent', 'heroSection');

    const updatedFields = {};
    for (const key in content) {
      if (content[key] !== '' && content[key] !== null) {
        updatedFields[key] = content[key];
      }
    }

    // Handle image uploads
    const aboutImagesUrls = await Promise.all(
      content.about_images.map(async (file, index) => {
        if (typeof file === 'object') {
          return await uploadFile(file, `homeContent/heroSection/about_image${index + 1}`);
        } else {
          return file;
        }
      })
    );

    updatedFields.about_images = aboutImagesUrls;

    await updateDoc(docRef, updatedFields);
    alert('Content updated successfully!');
  };

  const uploadFile = async (file, path) => {
    const fileRef = ref(storage, path);
    await uploadBytes(fileRef, file);
    return await getDownloadURL(fileRef);
  };

  return (
    <>
      <DashboardNavbar />
      <section className="relative z-20 overflow-hidden bg-white py-12 lg:pt-[80px] border-t border-gray-50">
        <div className="max-w-2xl px-4 sm:px-6">
          <h2 className="mb-4 text-xl font-bold text-dark">Edit About Section</h2>
          <form onSubmit={handleSubmit}>
            <div className="grid gap-4 sm:grid-cols-1 sm:gap-6">
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-gray-900">About Title</label>
                <input
                  type="text"
                  name="about_title"
                  value={content.about_title}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="About title"
                />
              </div>
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-gray-900">About Description</label>
                <ReactQuill
                  value={content.about_description}
                  onChange={(value) => handleEditorChange('about_description', value)}
                  className="bg-gray-50 border-none text-gray-900 text-sm focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                />
              </div>
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-gray-900">Button Text</label>
                <input
                  type="text"
                  name="about_cta"
                  value={content.about_cta}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="More About Us"
                />
              </div>
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-gray-900">Button Link (eg: /about)</label>
                <input
                  type="text"
                  name="about_cta_link"
                  value={content.about_cta_link}
                  onChange={handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="no need to enter entire url, eg: /about"
                />
              </div>
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-gray-900">About Image 1</label>
                <input
                  type="file"
                  name="about_image_0"
                  onChange={handleFileChange}
                  className="block w-full text-sm text-gray-900 border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600"
                />
                {content.about_images[0] && typeof content.about_images[0] === 'string' && (
                  <div>
                    <img src={content.about_images[0]} className="h-auto max-w-full rounded-lg object-cover mt-4" alt="About Image 1" />
                  </div>
                )}
              </div>
              <div className="sm:col-span-1">
                <label className="block mb-2 text-sm font-medium text-gray-900">About Image 2</label>
                <input
                  type="file"
                  name="about_image_1"
                  onChange={handleFileChange}
                  className="block w-full text-sm text-gray-900 border-gray-300 rounded-lg cursor-pointer bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-600"
                />
                {content.about_images[1] && typeof content.about_images[1] === 'string' && (
                  <div>
                    <img src={content.about_images[1]} className="h-auto max-w-full rounded-lg object-cover mt-4" alt="About Image 2" />
                  </div>
                )}
              </div>
            </div>

            <button type="submit" className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-info-500 rounded-lg">
              Update About Section
            </button>
          </form>
        </div>
      </section>
    </>
  );
}

export default AboutEditPage;
