import React, { useState, useEffect } from 'react';
import { db } from '../../../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import DashboardNavbar from '../DashboardNavbar';


function CategoriesListPage() {
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      const querySnapshot = await getDocs(collection(db, 'categories'));
      const categoriesData = [];
      querySnapshot.forEach((doc) => {
        categoriesData.push({ id: doc.id, ...doc.data() });
      });
      setCategories(categoriesData);
    };

    fetchCategories();
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredCategories = categories.filter(category => {
    return category.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
           category.description.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <>
    <DashboardNavbar />
    <section className="bg-white relative z-20 overflow-hidden bg-white py-8 lg:pt-[120px]">
      <div className="max-w-screen-7xl mx-auto px-8 sm:px-6">
        <h2 className="mb-4 text-xl font-bold text-dark">Categories</h2>
        <div className="mb-4 w-96">
          <label htmlFor="search" className="block mb-2 text-sm font-medium text-dark">Search</label>
          <input
            id="search"
            type="text"
            value={searchQuery}
            onChange={handleSearchChange}
            className="bg-white border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
            placeholder="Search by name or description"
          />
        </div>
        <Link to="/dashboard/categories/add" className="text-primary-700 mb-6 block">Add New Category</Link>
        <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {filteredCategories.map((category) => (
            <div key={category.id} className="w-full bg-white border border-gray-100 rounded-lg shadow">
              {category.imageUrl && (
                <img
                  src={category.imageUrl}
                  alt={category.name}
                  className="w-full h-64 object-cover rounded-t-lg"
                />
              )}
              <div className="p-4">
                <h5 className="text-md font-semibold tracking-tight text-dark">{category.name}</h5>
                <p className="text-sm mt-2 break-words mb-6">{category.description.length > 73 ? `${category.description.substring(0, 70)}...` : category.description}</p>
                <div className="flex items-center justify-between">
                  <Link
                    to={`/dashboard/categories/edit/${category.id}`}
                    className="text-white bg-info-500 font-medium rounded-md text-sm px-5 py-2.5 text-center"
                  >
                    Edit Category
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
    </>
  );
}

export default CategoriesListPage;