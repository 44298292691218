import React from 'react';

function PrivacyPolicy() {
  return (
    <>
      <section className="bg-white py-12 lg:py-[80px] border-t border-gray-50">
        <div className="max-w-screen-2xl mx-auto px-4 sm:px-4">
          <div className="flex flex-wrap">
            <div className="w-full px-4 lg:w-8/12">
              <div>
                <h2 className="mb-6 text-3xl font-bold text-dark sm:text-[32px] sm:leading-[40px]">
                  Privacy Policy
                </h2>
                <div className="mb-10 text-dark">
                  <p>
                    UK Roofing Supplies ("we," "us," or "our"), a company registered in the United Kingdom under company number 1345702, is committed to protecting the privacy and security of our customers and website visitors. This Privacy Policy outlines how we collect, use, and protect your personal data when you interact with us through our website.
                  </p>

                  <h3 className="mt-8 mb-4 text-2xl font-bold">1. Who We Are</h3>
                  <p>
                    <strong>Company Name:</strong> UK Roofing Supplies<br />
                    <strong>Registered Office:</strong> Unit 1 Petersfield Avenue, Slough, Berkshire, SL2 5EA<br />
                    <strong>Company Number:</strong> 1345702<br />
                    <strong>Contact Information:</strong><br />
                    - Phone: 01753 53980<br />
                    - Email: slough@ukroofingsupplies.co.uk<br />
                    - Business Hours: Mon - Fri, 07:00 to 16:30; Saturday, 08:00 to 12:00
                  </p>

                  <h3 className="mt-8 mb-4 text-2xl font-bold">2. What Data We Collect</h3>
                  <p>
                    We collect personal data when you voluntarily submit it to us through our contact form. The types of personal data we may collect include:
                  </p>
                  <ul className="list-disc ml-6">
                    <li>Full Name</li>
                    <li>Email Address</li>
                    <li>Phone Number (Optional)</li>
                    <li>Branch Selected (if applicable)</li>
                    <li>Message Content</li>
                  </ul>

                  <h3 className="mt-8 mb-4 text-2xl font-bold">3. How We Use Your Data</h3>
                  <p>
                    We collect and use your personal data for the following purposes:
                  </p>
                  <ul className="list-disc ml-6">
                    <li>Communication: To respond to inquiries or requests you make through our contact form.</li>
                    <li>Customer Support: To provide you with customer service and support.</li>
                    <li>Site Improvement: To improve our website and services based on feedback and analytics.</li>
                  </ul>

                  <h3 className="mt-8 mb-4 text-2xl font-bold">4. Google Analytics</h3>
                  <p>
                    Our website uses Google Analytics, a web analytics service provided by Google, Inc. ("Google"). Google Analytics uses cookies to collect information about how visitors use our website. The data collected is used to analyze website activity and improve our services.
                  </p>
                  <p>
                    <strong>Data Collected by Google Analytics</strong> may include:
                  </p>
                  <ul className="list-disc ml-6">
                    <li>IP Address</li>
                    <li>Browser Type</li>
                    <li>Operating System</li>
                    <li>Pages Visited</li>
                    <li>Time Spent on Pages</li>
                    <li>Referring URLs</li>
                  </ul>
                  <p>
                    Google may use the data collected to contextualize and personalize ads on its advertising network. For more information on how Google uses this data, please refer to <a href="https://policies.google.com/privacy" className="text-blue-500 underline">Google's Privacy Policy</a>.
                  </p>

                  <h3 className="mt-8 mb-4 text-2xl font-bold">5. Data Retention</h3>
                  <p>
                    We retain your personal data for as long as necessary to fulfill the purposes for which it was collected or as required by law. Specifically:
                  </p>
                  <ul className="list-disc ml-6">
                    <li>Contact Form Data: Retained only for the duration of communication with the customer and will be deleted thereafter, unless required for legal purposes.</li>
                    <li>Analytics Data: Retained as per Google's data retention policies.</li>
                  </ul>

                  <h3 className="mt-8 mb-4 text-2xl font-bold">6. Data Security</h3>
                  <p>
                    We take appropriate measures to protect your personal data from unauthorized access, disclosure, alteration, or destruction. These measures include technical, administrative, and physical safeguards.
                  </p>

                  <h3 className="mt-8 mb-4 text-2xl font-bold">7. Your Rights</h3>
                  <p>
                    Under the General Data Protection Regulation (GDPR), you have the following rights:
                  </p>
                  <ul className="list-disc ml-6">
                    <li>Access: You can request access to the personal data we hold about you.</li>
                    <li>Correction: You can request that we correct any inaccurate or incomplete personal data.</li>
                    <li>Deletion: You can request that we delete your personal data under certain conditions.</li>
                    <li>Objection: You can object to the processing of your personal data for specific purposes.</li>
                    <li>Portability: You can request that we transfer your personal data to another organization or directly to you.</li>
                  </ul>
                  <p>
                    To exercise any of these rights, please contact us at slough@ukroofingsupplies.co.uk.
                  </p>

                  <h3 className="mt-8 mb-4 text-2xl font-bold">8. Changes to This Policy</h3>
                  <p>
                    We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. We encourage you to review this policy periodically.
                  </p>

                  <h3 className="mt-8 mb-4 text-2xl font-bold">9. Contact Us</h3>
                  <p>
                    If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                  </p>
                  <p>
                    <strong>UK Roofing Supplies</strong><br />
                    Unit 1 Petersfield Avenue, Slough, Berkshire, SL2 5EA<br />
                    Phone: 01753 53980<br />
                    Email: slough@ukroofingsupplies.co.uk
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicy;
