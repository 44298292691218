import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import FiveStarSvg from './svg/FiveStarSvg';

function HomeTestimonials() {
  const [testimonials, setTestimonials] = useState([]);
  const [testimonialsContent, setTestimonialsContent] = useState({
    testimonials_pre_title: '',
    testimonials_title: '',
    testimonials_description: '',
  });

  useEffect(() => {
    const fetchTestimonials = async () => {
      const querySnapshot = await getDocs(collection(db, 'homeContent', 'heroSection', 'testimonials'));
      const testimonialsData = [];
      querySnapshot.forEach((doc) => {
        testimonialsData.push(doc.data());
      });
      setTestimonials(testimonialsData);
    };

    const fetchTestimonialsContent = async () => {
      const docRef = doc(db, 'homeContent', 'heroSection');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setTestimonialsContent(docSnap.data());
      }
    };

    fetchTestimonials();
    fetchTestimonialsContent();
  }, []);

  return (
    <section id="testimonials" className="overflow-hidden py-12 lg:py-[80px] border-t border-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex-wrap justify-center">
          <div className="w-full px-4">
            <div className="mb-[40px] text-start">
              <h2 className="mb-3 text-2xl sm:text-3xl font-bold text-dark">
                {testimonialsContent.testimonials_title}
              </h2>
              <p className="text-dark">{testimonialsContent.testimonials_description}</p>
            </div>
          </div>
        </div>

        <div className="flex overflow-x-auto space-x-4 snap-x snap-mandatory pb-4 scrollbar-visible">
          {testimonials.map((testimonial, index) => (
            <div 
              key={index} 
              className="flex-none w-[320px] sm:w-96 snap-center mb-6"
            >
              <div 
                className="bg-white p-6 border border-gray-100 rounded-lg shadow-md h-full flex flex-col min-h-[300px]" 
              >
                <FiveStarSvg />
                <p className="mt-0.5 text-lg font-medium text-dark">
                  {testimonial.testimonial_client_name}, {testimonial.testimonial_client_position}
                </p>
                <p className="mt-4 text-dark flex-grow">
                  {testimonial.testimonial_client_description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default HomeTestimonials;