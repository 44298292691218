import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import { enableAnalytics } from './firebaseConfig';
import CookieConsent from 'react-cookie-consent';

import EditBranchPage from './pages/Dashboard/EditBranchPage'
import EditProductsPage from './pages/Dashboard/EditProductsPage'
import EditAboutPage from './pages/Dashboard/EditAboutPage'
import HeroEditPage from './pages/Dashboard/HomePage/HeroEditPage';
import AboutEditPage from './pages/Dashboard/HomePage/AboutEditPage';
import FaqEditPage from './pages/Dashboard/HomePage/FaqEditPage';
import TestimonialsEditPage from './pages/Dashboard/HomePage/TestimonialsEditPage';
import ServicesEditPage from './pages/Dashboard/HomePage/ServicesEditPage';
import BranchEditPage from './pages/Dashboard/HomePage/BranchEditPage';
import CategoriesListPage from './pages/Dashboard/Categories/CategoriesListPage';
import AddCategoryPage from './pages/Dashboard/Categories/AddCategoryPage';
import EditCategoryPage from './pages/Dashboard/Categories/EditCategoryPage';
import ProductsListPage from './pages/Dashboard/Products/ProductsListPage';
import AddProductPage from './pages/Dashboard/Products/AddProductPage';
import EditProductPage from './pages/Dashboard/Products/EditProductPage';
import EditContactPage from './pages/Dashboard/EditContactPage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import ContactUs from './pages/ContactUs';
import Products from './pages/Products';
import About from './pages/About';
import PrivacyPolicy from './pages/PrivacyPolicy';
import OurBranches from './pages/OurBranches';
import PublicProductsPage from './pages/PublicProductsPage';
import PrivateRoute from './components/PrivateRoute';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/products" element={<PublicProductsPage />} />
        <Route path="/branches" element={<OurBranches />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard/edit-branch-page" element={<PrivateRoute><EditBranchPage /></PrivateRoute>} />
        <Route path="/dashboard/edit-products-page" element={<PrivateRoute><EditProductsPage /></PrivateRoute>} />
        <Route path="/dashboard/home-page/hero" element={<PrivateRoute><HeroEditPage /></PrivateRoute>} />
        <Route path="/dashboard/home-page/about" element={<PrivateRoute><AboutEditPage /></PrivateRoute>} />
        <Route path="/dashboard/home-page/faq" element={<PrivateRoute><FaqEditPage /></PrivateRoute>} />
        <Route path="/dashboard/home-page/testimonials" element={<PrivateRoute><TestimonialsEditPage /></PrivateRoute>} />
        <Route path="/dashboard/home-page/services" element={<PrivateRoute><ServicesEditPage /></PrivateRoute>} />
        <Route path="/dashboard/home-page/branches" element={<PrivateRoute><BranchEditPage /></PrivateRoute>} />
         <Route path="/dashboard/edit-about-page" element={<PrivateRoute><EditAboutPage /></PrivateRoute>} />
         <Route path="/dashboard/edit-contact-page" element={<PrivateRoute><EditContactPage /></PrivateRoute>} />
        <Route path="/dashboard/categories" element={<PrivateRoute><CategoriesListPage /></PrivateRoute>} />
        <Route path="/dashboard/categories/add" element={<PrivateRoute><AddCategoryPage /></PrivateRoute>} />
        <Route path="/dashboard/categories/edit/:id" element={<PrivateRoute><EditCategoryPage /></PrivateRoute>} />
        <Route path="/dashboard/products" element={<PrivateRoute><ProductsListPage /></PrivateRoute>} />
        <Route path="/dashboard/products/add" element={<PrivateRoute><AddProductPage /></PrivateRoute>} />
        <Route path="/dashboard/products/edit/:id" element={<PrivateRoute><EditProductPage /></PrivateRoute>} />
        
      </Routes>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="I understand"
        enableDeclineButton // This enables the "Reject All Cookies" button
        declineButtonText="Reject All Cookies"
        cookieName="myAwesomeCookieName"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        declineButtonStyle={{ color: "#fff", background: "#ff0000", fontSize: "13px" }}
        expires={150}
        onAccept={() => {
          // Enable Firebase Analytics once the user has given consent
          if (typeof window !== "undefined" && window.gtag) {
            window.gtag('consent', 'update', {
              'analytics_storage': 'granted'
            });
          }
        }}
        onDecline={() => {
          // Disable Firebase Analytics if the user rejects cookies
          if (typeof window !== "undefined" && window.gtag) {
            window.gtag('consent', 'update', {
              'analytics_storage': 'denied'
            });
          }
        }}
      >
        This website uses cookies to enhance the user experience.{" "}
        <a href="/privacy-policy" style={{ color: "#ffffff" }}>Read our privacy policy</a>.
      </CookieConsent>
    </Router>
  );
}

export default App;