import React, { useEffect, useState } from 'react';
import { db } from '../firebaseConfig';
import { doc, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import BackgroundSvg from './svg/BackgroundSvg';

function HomeBranch() {
  const [branchContent, setBranchContent] = useState(null);

  useEffect(() => {
    const fetchBranchContent = async () => {
      try {
        const docRef = doc(db, 'homeContent', 'heroSection');
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setBranchContent(docSnap.data());
        } else {
          console.log('No such document!');
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };

    fetchBranchContent();
  }, []);

  // Return null if branchContent is not available
  if (!branchContent) {
    return null;
  }

  const { branch_title, branch_description, branch_cta, branch_cta_link } = branchContent;

  return (
    <section className="relative z-10 overflow-hidden bg-primaryDark py-12 lg:py-[100px] border-t border-gray-50">
      <div className="max-w-screen-7xl mx-auto px-8 sm:px-6">
        <div className="relative overflow-hidden">
          <div className="-mx-4 flex flex-wrap items-stretch">
            <div className="w-full px-4">
              <div className="mx-auto max-w-[570px] text-center">
                <h2 className="mb-2.5 text-2xl sm:text-3xl font-bold text-white">
                  <span>{branch_title}</span>
                </h2>
                <p className="mx-auto mb-6 max-w-[515px] text-dark leading-[1.5] text-white">
                  {branch_description}
                </p>
               
                <Link 
                  to={branch_cta_link} 
                  className="inline-block rounded-md bg-white px-7 py-3 text-dark font-medium text-primaryDark"
                >
                  {branch_cta}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BackgroundSvg />
    </section>
  );
}

export default HomeBranch;
