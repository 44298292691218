import React, { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';  
import { doc, getDoc } from 'firebase/firestore';
import ContactForm from '../components/ContactForm';
import BranchTabs from '../components/BranchTabs';
import Hero from '../components/Hero';

function OurBranches() {
  const [heroData, setHeroData] = useState({
    branch_hero_title: '',
    branch_hero_description: '',
    branchHeroImageUrl: '', 
  });

  useEffect(() => {
    const fetchHeroContent = async () => {
      const docRef = doc(db, 'homeContent', 'heroSection');
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setHeroData({
          branch_hero_title: data.branch_hero_title,
          branch_hero_description: data.branch_hero_description,
          branchHeroImageUrl: data.heroBranchImageUrl,
        });
      } else {
        console.log('No such document!');
      }
    };

    fetchHeroContent();
  }, []);

  return (
    <>  
      <Hero 
        className="contact-hero-background-gradient"
        title={heroData.branch_hero_title}
        description={heroData.branch_hero_description}
        backgroundImage={heroData.branchHeroImageUrl}  // Pass the URL as backgroundImage
      />
      <section id="contact" className="relative z-20 overflow-hidden bg-white py-8 lg:pt-[120px]">
        <div className="max-w-screen-2xl mx-auto px-8 sm:px-6">
          <BranchTabs />
          <div className="mt-12 flex flex-wrap items-center">
            <div className="w-full lg:w-2/3">
              <ContactForm />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OurBranches;
