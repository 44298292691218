import React, { useState, useEffect } from 'react';
import { db } from '../../../firebaseConfig';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import DashboardNavbar from '../DashboardNavbar';

function BranchEditPage() {
  const [generalContent, setGeneralContent] = useState({
    branch_title: '',
    branch_description: '',
    branch_cta: '',
    branch_cta_link: '',
  });

  const [branches, setBranches] = useState([]);

  useEffect(() => {
    const fetchContent = async () => {
      const docRef = doc(db, 'homeContent', 'heroSection');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setGeneralContent({
          branch_title: data.branch_title,
          branch_description: data.branch_description,
          branch_cta: data.branch_cta,
          branch_cta_link: data.branch_cta_link,
        });
        setBranches(data.branches || []);
      } else {
        console.log('No such document!');
      }
    };

    fetchContent();
  }, []);

  const handleGeneralChange = (e) => {
    const { name, value } = e.target;
    setGeneralContent({
      ...generalContent,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const docRef = doc(db, 'homeContent', 'heroSection');
    await updateDoc(docRef, { ...generalContent, branches });
    alert('Branch information updated successfully!');
  };

  return (
    <>
      <DashboardNavbar />
      <section className="relative z-20 overflow-hidden bg-white py-12 lg:pt-[80px] border-t border-gray-50">
        <div className="max-w-2xl px-4 sm:px-6">
          <h2 className="mb-4 text-xl font-bold text-dark">Edit Branch Section</h2>
          
          {/* General Branch Information */}
          <form onSubmit={handleSubmit}>
            <div className="mb-8 mt-4">
              <div className="sm:col-span-1 mb-4">
                <label className="block mb-2 text-sm font-medium text-dark">Branch Title</label>
                <input
                  type="text"
                  name="branch_title"
                  value={generalContent.branch_title}
                  onChange={handleGeneralChange}
                  className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Branch title"
                />
              </div>
              <div className="sm:col-span-1 mb-4">
                <label className="block mb-2 text-sm font-medium text-dark">Branch Description</label>
                <textarea
                  name="branch_description"
                  value={generalContent.branch_description}
                  onChange={handleGeneralChange}
                  className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Branch description"
                  rows="5"
                ></textarea>
              </div>
              <div className="sm:col-span-1 mb-4">
                <label className="block mb-2 text-sm font-medium text-dark">Branch Button Text (Home Page Section)</label>
                <input
                  type="text"
                  name="branch_cta"
                  value={generalContent.branch_cta}
                  onChange={handleGeneralChange}
                  className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Button text"
                />
              </div>
              <div className="sm:col-span-1 mb-4">
                <label className="block mb-2 text-sm font-medium text-dark">Branch Link (e.g., /branches)</label>
                <input
                  type="text"
                  name="branch_cta_link"
                  value={generalContent.branch_cta_link}
                  onChange={handleGeneralChange}
                  className="bg-gray-50 border border-gray-300 text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                  placeholder="Branch link"
                />
              </div>
            </div>

            <button
              type="submit"
              className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-info-500 rounded-lg"
            >
              Update Branch Information
            </button>
          </form>
        </div>
      </section>
    </>
  );
}

export default BranchEditPage;
