import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Logout from '../../components/Logout';

const DashboardNavbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <section className="relative z-50 bg-white py-8">
      <div className="w-full px-4 sm:px-6 flex justify-between items-center">
        <h3 className="text-xl font-bold tracking-tight text-dark">Dashboard</h3>
      </div>
      <div className="w-full px-4 sm:px-6 flex justify-between items-center">
        <nav>
          <ul className="flex space-x-10">
            <li className="relative" ref={dropdownRef}>
              <button
                onClick={toggleDropdown}
                className="text-dark focus:outline-none flex items-center"
              >
                Home Page
                <span className="ml-1">
                  <ArrowDropDownIcon className="text-dark" />
                </span>
              </button>

              {dropdownOpen && (
                <ul className="absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-md py-1 z-50">
                  <li>
                    <NavLink
                      to="/dashboard/home-page/hero"
                      className={({ isActive }) =>
                        isActive ? 'text-primary block px-4 py-2' : 'text-dark block px-4 py-2'
                      }
                      onClick={toggleDropdown}
                    >
                      Hero
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/home-page/about"
                      className={({ isActive }) =>
                        isActive ? 'text-primary block px-4 py-2' : 'text-dark block px-4 py-2'
                      }
                      onClick={toggleDropdown}
                    >
                      About
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/home-page/faq"
                      className={({ isActive }) =>
                        isActive ? 'text-primary block px-4 py-2' : 'text-dark block px-4 py-2'
                      }
                      onClick={toggleDropdown}
                    >
                      FAQ
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/home-page/testimonials"
                      className={({ isActive }) =>
                        isActive ? 'text-primary block px-4 py-2' : 'text-dark block px-4 py-2'
                      }
                      onClick={toggleDropdown}
                    >
                      Testimonials
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/home-page/services"
                      className={({ isActive }) =>
                        isActive ? 'text-primary block px-4 py-2' : 'text-dark block px-4 py-2'
                      }
                      onClick={toggleDropdown}
                    >
                      Services
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/dashboard/home-page/branches"
                      className={({ isActive }) =>
                        isActive ? 'text-primary block px-4 py-2' : 'text-dark block px-4 py-2'
                      }
                      onClick={toggleDropdown}
                    >
                      Branch
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <NavLink
                to="/dashboard/edit-branch-page"
                className={({ isActive }) =>
                  isActive ? 'text-primary border-b-2 border-primary-500 pb-2' : 'text-dark'
                }
              >
                Branch Page
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/edit-contact-page"
                className={({ isActive }) =>
                  isActive ? 'text-primary border-b-2 border-primary-500 pb-2' : 'text-dark'
                }
              >
                Contact Page
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/edit-about-page"
                className={({ isActive }) =>
                  isActive ? 'text-primary border-b-2 border-primary-500 pb-2' : 'text-dark'
                }
              >
                About Us Page
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/edit-products-page"
                className={({ isActive }) =>
                  isActive ? 'text-primary border-b-2 border-primary-500 pb-2' : 'text-dark'
                }
              >
                Products Page
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/categories"
                className={({ isActive }) =>
                  isActive ? 'text-primary border-b-2 border-primary-500 pb-2' : 'text-dark'
                }
              >
                All Categories
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/dashboard/products"
                className={({ isActive }) =>
                  isActive ? 'text-primary border-b-2 border-primary-500 pb-2' : 'text-dark'
                }
              >
                All Products
              </NavLink>
            </li>
            <li>
              <Logout />
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
};

export default DashboardNavbar;