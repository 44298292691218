import React, { useState, useEffect } from 'react';
import { db } from '../../../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import DashboardNavbar from '../DashboardNavbar';

function ProductsListPage() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state && location.state.selectedCategory) {
      setSelectedCategory(location.state.selectedCategory);
    }

    const fetchData = async () => {
      const productsSnapshot = await getDocs(collection(db, 'products'));
      const categoriesSnapshot = await getDocs(collection(db, 'categories'));

      const productsData = [];
      const tagsSet = new Set();
      productsSnapshot.forEach((doc) => {
        const productData = doc.data();
        productsData.push({
          id: doc.id,
          ...productData
        });
        if (productData.tag) {
          tagsSet.add(productData.tag);
        }
      });

      const categoriesData = [];
      categoriesSnapshot.forEach((doc) => {
        categoriesData.push(doc.data().name);
      });

      setProducts(productsData);
      setCategories(categoriesData);
      setTags([...tagsSet]);
    };

    fetchData();
  }, [location.state]);

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  const handleTagChange = (e) => {
    setSelectedTag(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredProducts = products.filter(product => {
    const matchesCategory = selectedCategory ? product.categoryName === selectedCategory : true;
    const matchesTag = selectedTag ? product.tag === selectedTag : true;
    const matchesSearch = product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
                          product.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
                          product.categoryName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                          product.tag.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesCategory && matchesTag && matchesSearch;
  });

  const handleEditProduct = (productId) => {
    navigate(`/dashboard/products/edit/${productId}`, { state: { selectedCategory } });
  };

  return (
    <>
      <DashboardNavbar />
      <section className="bg-white relative z-20 overflow-hidden bg-white py-8 lg:pt-[120px]">
        <div className="max-w-screen-7xl mx-auto px-8 sm:px-6">
          <h2 className="mb-4 text-xl font-bold text-dark">Products</h2>
          <div className="mb-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <div>
              <label htmlFor="category" className="block mb-2 text-sm font-medium text-dark">Filter by Category</label>
              <select
                id="category"
                value={selectedCategory}
                onChange={handleCategoryChange}
                className="bg-white border border-dark text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              >
                <option value="">All Categories</option>
                {categories.map((category, index) => (
                  <option key={index} value={category}>{category}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="tag" className="block mb-2 text-sm font-medium text-dark">Filter by Tag</label>
              <select
                id="tag"
                value={selectedTag}
                onChange={handleTagChange}
                className="bg-white border border-dark text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              >
                <option value="">All Tags</option>
                {tags.map((tag, index) => (
                  <option key={index} value={tag}>{tag}</option>
                ))}
              </select>
            </div>
            <div>
              <label htmlFor="search" className="block mb-2 text-sm font-semibold text-success-700">Search keywords (It's easy)</label>
              <input
                id="search"
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                className="bg-white border border-dark text-dark text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                placeholder="Search by title, description, category, or tag"
              />
            </div>
          </div>
          <Link to="/dashboard/products/add" className="text-primary-700 mb-4 inline-block">Add New Product</Link>
          <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            {filteredProducts.map((product) => (
              <div key={product.id} className="w-full bg-white border border-gray-100 rounded-lg shadow">
                {product.imageUrl && (
                  <img
                    src={product.imageUrl}
                    className="w-full h-64 object-cover rounded-t-lg"
                    alt={product.title}
                  />
                )}
                <div className="p-4">
                  <h5 className="text-md font-semibold tracking-tight text-dark">{product.title}</h5>
                  <p className="text-sm text-dark mt-2 break-words">{product.description.length > 73 ? `${product.description.substring(0, 73)}...` : product.description}</p>
                  <div className="flex items-center mt-2.5 mb-2">
                    <div className="flex items-center space-x-1 rtl:space-x-reverse text-md text-dark font-semibold">
                      Category:
                    </div>
                    <span className="bg-primaryDark text-white text-xs font-semibold px-2.5 py-0.5 rounded ms-3">{product.categoryName}</span>
                  </div>
                  <div className="flex items-center mt-2.5 mb-5">
                    <div className="flex text-md text-dark items-center space-x-1 rtl:space-x-reverse font-semibold">
                      Tag:
                    </div>
                    <span className="bg-success-600 text-white text-xs font-semibold px-2.5 py-0.5 rounded ms-3">{product.tag}</span>
                  </div>
                  <div className="flex items-center justify-between">
                    {product.price && (
                      <span className="text-xl font-bold text-dark">${product.price}</span>
                    )}
                    <button
                      onClick={() => handleEditProduct(product.id)}
                      className="inline-flex items-center px-5 py-2.5 mt-4 sm:mt-6 text-sm font-medium text-center text-white bg-info-500 rounded-lg"
                    >
                      Edit Product
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default ProductsListPage;