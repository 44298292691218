import React, { useState, useEffect } from 'react';
import { db, storage } from '../../../firebaseConfig';
import { doc, setDoc, collection, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import DashboardNavbar from '../DashboardNavbar';


function AddProductPage() {
  const [product, setProduct] = useState({
    title: '',
    description: '',
    categoryName: '',
    tag: '',
    image: null,
  });

  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'categories'));
        const categoriesData = [];
        querySnapshot.forEach((doc) => {
          categoriesData.push({ name: doc.id, ...doc.data() });
        });
        setCategories(categoriesData);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'image' && files[0]) {
      setProduct({
        ...product,
        [name]: files[0],
      });
    } else {
      setProduct({
        ...product,
        [name]: value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { title, description, categoryName, tag, image } = product;

      // Create a sanitized product ID based on the title
      const productId = title.replace(/\s+/g, '-').toLowerCase();

      // Upload the image to Firebase Storage and get the download URL
      const imageRef = ref(storage, `products/${productId}/${image.name}`);
      await uploadBytes(imageRef, image);
      const imageUrl = await getDownloadURL(imageRef);

      // Create a document in Firestore with the product ID as the document ID
      await setDoc(doc(db, 'products', productId), {
        title,
        description,
        categoryName,
        tag,
        imageUrl,
      });

      alert('Product added successfully!');
      setProduct({ title: '', description: '', categoryName: '', tag: '', image: null });
    } catch (error) {
      console.error('Error adding product:', error);
      alert('Error adding product. Please try again.');
    }
  };

  return (
    <>
      <DashboardNavbar />
      <section className="relative z-20 overflow-hidden bg-white py-12 lg:pt-[80px] border-t border-gray-50">
        <div className="max-w-2xl px-4 sm:px-6">
          <h2 className="mb-4 text-xl font-bold text-dark">Add New Product</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-sm font-medium text-dark">Product Title</label>
              <input
                type="text"
                name="title"
                value={product.title}
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md p-2 shadow-sm focus:ring focus:ring-opacity-50"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-dark">Description</label>
              <textarea
                name="description"
                value={product.description}
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md p-2 shadow-sm focus:ring focus:ring-opacity-50"
              ></textarea>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-dark">Category</label>
              <select
                name="categoryName"
                value={product.categoryName}
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md p-2 shadow-sm focus:ring focus:ring-opacity-50"
              >
                <option value="">Select Category</option>
                {categories.map((category) => (
                  <option key={category.name} value={category.name}>
                    {category.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-dark">Tag</label>
              <input
                type="text"
                name="tag"
                value={product.tag}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md p-2 shadow-sm focus:ring focus:ring-opacity-50"
                placeholder="Product Tag"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-dark">Image</label>
              <input
                type="file"
                name="image"
                onChange={handleChange}
                required
                className="mt-1 block w-full border border-gray-300 rounded-md p-2 shadow-sm focus:ring focus:ring-opacity-50"
              />
            </div>
            <button
              type="submit"
              className="px-4 py-2 bg-info-500 text-white rounded-md hover:bg-blue-700"
            >
              Add Product
            </button>
          </form>
        </div>
      </section>
    </>
  );
}

export default AddProductPage;